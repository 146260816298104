<template functional>
  <div
    :ref="data.ref"
    :class="[data.class, data.staticClass, 'sf-loader', 'price-loader']"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <transition mode="out-in" name="sf-fade">
      <slot v-if="!props.loading"></slot>
      <div v-else class="sf-loader__overlay">
        <slot name="price-loader loader">
          <div class="price-loader__container">
            <div class="price-loader__msg">Prix personnalisé et disponibilité en cours de chargement...</div>
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "SfLoader",
  props: {
    loading: {
      default: true,
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/components/atoms/SfLoader.scss";

.price-loader {
  height: 75px !important;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__msg {
    color: var(--c-primary);
    @include font(
        --product-description-font,
        var(--font-weight--normal),
        var(--font-size--sm),
        1,
        var(--font-family--primary)
    );
  }
}
</style>
