const fragmentCategory = `
  fragment CategoryFields on CategoryTree {
    include_in_menu
    is_anchor
    level
    meta_description
    meta_title
    name
    position
    product_count
    uid
    url_path
    url_suffix
    custom_canonical_url
    ext_uniq_id
  }
`;

const fragmentCategoryUniverse = `
  fragment CategoryFieldsUniverse on CategoryTree {
    include_in_menu
    is_anchor
    level
    meta_description
    meta_title
    name
    position
    product_count
    uid
    universe_mega_menu_cms
    custom_canonical_url
    ext_uniq_id
    url_path
    url_suffix
  }
`;

export default `
  query categoryList {
    categories {
      items {
        ...CategoryFields
        children {
          ...CategoryFieldsUniverse
          children {
            ...CategoryFields
            children {
              ...CategoryFields
              children {
                ...CategoryFields
                children {
                  ...CategoryFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${[fragmentCategory, fragmentCategoryUniverse]}
`;
