

















import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import type { NuxtError } from '@nuxt/types';

export default defineComponent({
  layout: 'full-width',
  components: {
    SfButton,
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: false,
    },
  },
});
