<template>
  <div className="shop-infos">
    <div>
      <div v-if="shop">
        <div v-if="show" className="underline">
          <span className="storeText">Votre magasin : </span>
          <ShopName :brand="shop.locationName" :locality="shop.address.locality" class="name"/>
        </div>
        <div v-else>
          <ShopName :brand="shop.locationName" :locality="shop.address.locality"/>
        </div>
      </div>
      <div v-else>
        <div v-if="show">
          {{ $t("Choose your store") }}
          <SvgImage
            class="rotate"
            height="10"
            icon="Icon_chevron--white"
            width="10"
          />
        </div>
        <div v-else>
          {{ $t("Choose your store") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { defineComponent, onMounted, ref, watch } from '@nuxtjs/composition-api';
import ShopName from "~/modules/shop/components/ShopName";
import { useShop } from '~/modules/shop/composables/useShop';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: "ShopNameInformation",
  components: {
    ShopName,
    SvgImage
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const customerStore    = useCustomerStore();
    const { currentShop }  = storeToRefs(customerStore);
    const customerShopCode = ref('');
    const shop             = ref('');
    const { load }         = useShop();

    const getShopData = async () => {
      customerShopCode.value = customerStore.getCurrentShopCode;
      shop.value             = await load({ storeCode: customerShopCode.value });
    }

    onMounted(async () => {
      await getShopData();
    })

    watch(currentShop, async () => {
      await getShopData();
    });

    return {
      shop
    }
  },
});

</script>

<style lang="scss" scoped>
.shop-infos {
  span {
    margin-left: 10px;
  }

  .rotate {
    transform: rotate(270deg);
  }

  .name {
    text-decoration: underline;
  }
}
</style>
