<template>
  <div
    v-if="link"
    class="mega-menu-navbar-link"
  >
    <div class="mega-menu-item mega-menu-item-button">
      <slot name="desktop-navigation-item">
        <SfLink
          :link="urlPath"
          :target="categoryType == 2? '_blank': '' "
          class="mega-menu-item-link"
        >
          <span class="label">
                {{ name }}
          </span>
          <span class="sf-icon smartphone-only">
            <SvgImage
              height="7"
              icon="Icon_chevron--white"
              width="14"
            />
          </span>
        </SfLink>
      </slot>
    </div>
  </div>
</template>
<script>
import { SfLink } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';

export default {
  name: 'HeaderNavigationItemsLink',
  components: {
    SfLink,
    SvgImage,
  },
  props: {
    link: {
      type: [String, Object],
      default: '',
    },
    urlPath: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    categoryType: {
      type: Number,
      default: 0,
    }
  },
};
</script>
