import { Wishlist } from '~/modules/GraphQL/types';
import { isArray } from 'lodash-es';

export const findWishlistWithID = (wishlists: [Wishlist], wishlistId: string): Wishlist|undefined => {
  if (isArray(wishlists)) {
    return wishlists.find((wishlist) => wishlist.id === wishlistId)
  }

  return undefined
};
