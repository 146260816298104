import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type {
  UseConfigurableProductsConfigurationErrors,
  UseConfigurableProductsConfigurationInterface
} from './useConfigurableProductsConfiguration';

export function useConfigurableProductsConfiguration(): UseConfigurableProductsConfigurationInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseConfigurableProductsConfigurationErrors>({
    getConfigurableProductsConfiguration: null
  });

  const search = async (sku) => {
    Logger.debug(`useConfigurableProductsConfiguration/getConfigurableProductsConfiguration/sku =`, sku);
    let configurableProductsConfiguration = null;
    try {
      loading.value = true;

      // @ts-ignore @TODO add establishment to ApiClientMethods
      configurableProductsConfiguration = await app.$vsf.$magento.api.configurableProductsConfiguration(sku, sku?.customQuery ?? null);
      error.value.getConfigurableProductsConfiguration = null;
    } catch (err) {
      error.value.getConfigurableProductsConfiguration = err;
      Logger.error(`useConfigurableProductsConfiguration/getConfigurableProductsConfiguration`, err);
    } finally {
      loading.value = false;
    }

    return configurableProductsConfiguration;
  };

  return {
    search,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useConfigurableProductsConfiguration';
export default useConfigurableProductsConfiguration;
