import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { useUserOptinsErrors, useUserOptinsInterface } from './useUserOptins';

export function useUserOptins(): useUserOptinsInterface {
  const { app } = useContext();
  const loading = ref(false);

  const error = ref<useUserOptinsErrors>({
    setOptins: null
  });

  const setOptins = async (email, sms) => {
    Logger.debug(`useUserOptins/setCustomerOptins`, `${email} ${sms}`);

    let result = null;
    const apiState = app.context.$vsf.$magento.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.setCustomerOptins(email, sms);
      Logger.debug(`[Result] useUserOptins/setCustomerOptins : `, result);
      error.value.setOptins = null;
    } catch (err) {
      error.value.setOptins = err;
      Logger.error(`useUserOptins/setCustomerOptins`, err);
    } finally {
      loading.value = false;
    }

    return result;
  }

  return {
    setOptins,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useUserOptins';
export default useUserOptins;
