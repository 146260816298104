import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseOrderWaitingForApprovalErrors, UseOrderWaitingForApprovalInterface } from './useOrderWaitingForApproval';

export function useOrderWaitingForApproval(): UseOrderWaitingForApprovalInterface {
  const { app } = useContext();
  const loading = ref(false);

  const token = app.$cookies.get('shop_token_id', { parseJSON: false });
  const tokenId = token ? token : '';

  const error = ref<UseOrderWaitingForApprovalErrors>({
    getList: null,
    removeItem: null,
    updateItem: null,
    approveOrder: null,
  });

  const getList = async (order_id = null) => {
    let result = null;
    const apiState = app.context.$vsf.$magento.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.getOrderWaitingForApproval(order_id, tokenId);
      Logger.debug(`[Result] useOrderWaitingForApproval/getList : `, result);
      error.value.getList = null;
    } catch (err) {
      error.value.getList = err;
      Logger.error(`useOrderWaitingForApproval/getList`, err);
    } finally {
      loading.value = false;
    }

    return result?.data?.getOrderWaitingForApproval;
  };

  const removeItem = async (order_id = null, item_id = null) => {
    let result = null;
    const apiState = app.context.$vsf.$magento.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.removeOrderWaitingForApprovalItem(order_id, item_id);
      Logger.debug(`[Result] useOrderWaitingForApproval/removeItem : `, result);
      error.value.removeItem = null;
    } catch (err) {
      error.value.removeItem = err;
      Logger.error(`useOrderWaitingForApproval/removeItem`, err);
    } finally {
      loading.value = false;
    }

    return result?.data?.removeOrderWaitingForApprovalItem;
  };

  const updateItem = async (order = null, item = null, quantity = 1) => {
    let result = null;
    const apiState = app.context.$vsf.$magento.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.updateOrderWaitingForApprovalItem(order, item, quantity);
      Logger.debug(`[Result] useOrderWaitingForApproval/updateItem : `, result);
      error.value.updateItem = null;
    } catch (err) {
      error.value.updateItem = err;
      Logger.error(`useOrderWaitingForApproval/updateItem`, err);
    } finally {
      loading.value = false;
    }

    return result?.data?.updateOrderWaitingForApprovalItem;
  };

  const approveOrder = async (order = null) => {
    let result = null;
    const apiState = app.context.$vsf.$magento.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.approveOrderWaitingForApproval(order);
      Logger.debug(`[Result] useOrderWaitingForApproval/approveOrder : `, result);
      error.value.updateItem = null;
    } catch (err) {
      error.value.updateItem = err;
      Logger.error(`useOrderWaitingForApproval/approveOrder`, err);
    } finally {
      loading.value = false;
    }

    return result?.data?.approveOrderWaitingForApproval;
  };

  return {
    getList,
    removeItem,
    updateItem,
    approveOrder,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useOrderWaitingForApproval';
export default useOrderWaitingForApproval;
