import { useUser } from '~/modules/customer/composables/useUser';
import { Logger } from '~/helpers/logger';

export const activeMessage = () => {
  const { isAuthenticated } = useUser();

  const getMessage = (message) => {
    if (message) {
      if (isAuthenticated) {
        return message;
      } else {
        Logger.error('The customer isn\'t logged in');
        return 0;
      }
    }
  }

  return { getMessage };
}

