


















































import LazyHydrate from 'vue-lazy-hydration';
import { computed, defineComponent, onMounted, ref, useRoute, watch } from '@nuxtjs/composition-api';
import { Cart, useUiState, useUser } from '~/composables';
import BannerConnectAs from '~/components/molecules/BannerConnectAs';
import PictimeHeader from '~/components/organisms/PictimeHeader';
import BottomNavigation from '~/components/BottomNavigation';
import IconSpriteTollens from '~/components/General/IconSpriteTollens';
import IconSpriteZolpan from '~/components/General/IconSpriteZolpan';
import TopBar from '~/components/TopBar/TopBar';
import CromologyStoreLocator from '~/components/CromologyStoreLocator';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCurrentShop } from '~/modules/customer/composables/useCurrentShop';
import { useCartStore } from '~/modules/checkout/stores/cart';
import MessageBanner from '~/components/MessageBanner.vue';
import { customerShopCode } from '~/modules/customer/helpers/customerShopCode';
import { storeToRefs } from 'pinia';
import CreateWishlistSidebar from '~/modules/wishlist/components/CreateWishlistSidebar.vue';
import RenameWishlistSidebar from '~/modules/wishlist/components/RenameWishlistSidebar.vue';
import AddToWishlistSidebar from '~/modules/wishlist/components/AddToWishlistSidebar.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    RenameWishlistSidebar,
    CreateWishlistSidebar,
    AddToWishlistSidebar,
    LazyHydrate,
    PictimeHeader,
    BannerConnectAs,
    BottomNavigation,
    IconSpriteTollens,
    IconSpriteZolpan,
    TopBar,
    CromologyStoreLocator,
    CromoFooter: () => import(/* webpackPrefetch: true */ '~/components/CromoFooter.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    PasswordSidebar: () => import(/* webpackPrefetch: true */ '~/components/PasswordSidebar.vue'),
    CartApprovalModal: () => import(/* webpackPrefetch: true */ '~/modules/cart/components/CartApprovalModal.vue'),
    ChangeShopConfirmationSidebar: () => import(/* webpackPrefetch: true */ '~/components/ChangeShopConfirmationSidebar.vue'),
    AddToCartSidebar: () => import(/* webpackPrefetch: true */ '~/components/AddToCartSidebar.vue'),
    MessageBanner
  },
  head() {
    return {
      script: [
        {
          src: '/script/didomi.min.js'
        }
      ],
    }
  },
  setup() {
    const { isAuthenticated } = useUser();
    const customerStore = useCustomerStore();
    const cartStore = useCartStore();
    const openLocator = ref(false);
    const route = useRoute();

    const {
      isLoginModalOpen,
      toggleLoginModal,
      isPasswordSidebarOpen,
      isApprovalModalOpen,
      toggleChangeShopConfirmationSidebar,
      isChangeShopConfirmationSidebarOpen,
      isPendingModalOpen,
      isCreateWishlistSidebarOpen,
      isRenameWishlistSidebarOpen,
      isAddToWishlistSidebarOpen,
    } = useUiState();

    const { setCustomerShopCode } = customerShopCode();

    const { set: setCurrent, get: getCurrent } = useCurrentShop();

    const setCurrentShopCode = async (code) => {
      let result = await setCurrent(code);

      if (result.current_shop) {
        setCustomerShopCode(result.current_shop);

        window.location.reload();
      }
    };

    const shopCode = ref('');

    const getCart = (): Cart => {
      return cartStore.getCart;
    };

    const openStoreLocator = () => {
      if (!isChangeShopConfirmationSidebarOpen.value) {
        if (getCart() && getCart().total_quantity > 0) {
          toggleChangeShopConfirmationSidebar();
        } else {
          openLocator.value = true;
        }
      } else {
        toggleChangeShopConfirmationSidebar();
        openLocator.value = true;
      }
    }

    const { currentShop } = storeToRefs(customerStore);

    onMounted(async () => {
      await isAuthenticated();
      const current = await getCurrent();
      setCustomerShopCode(current.current_shop);
    })

    watch(currentShop, async () => {
      shopCode.value = customerStore.getCurrentShopCode;
    });

    // On affiche pas la bannière sur HP et page panier
    const showMessageBanner = computed(() => route.value.name !== 'home' && route.value.name !== 'cart');

    return {
      isLoginModalOpen,
      toggleLoginModal,
      route,
      isPasswordSidebarOpen,
      shopCode,
      setCurrentShopCode,
      isApprovalModalOpen,
      openStoreLocator,
      openLocator,
      isChangeShopConfirmationSidebarOpen,
      toggleChangeShopConfirmationSidebar,
      isPendingModalOpen,
      showMessageBanner,
      isCreateWishlistSidebarOpen,
      isRenameWishlistSidebarOpen,
      isAddToWishlistSidebarOpen,
    };
  },
});
