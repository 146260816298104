import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseQuoteRejectedErrors, UseQuoteRejectedInterface } from './useQuoteRejected';

export function useQuoteRejected(): UseQuoteRejectedInterface {
  const { app } = useContext();
  const loading = ref(false);

  const error = ref<UseQuoteRejectedErrors>({
    updateQuoteRejected: null,
  });

  const update = async (quoteId) => {
    let result = null;
    const apiState = app.context.$vsf.$magento.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.updateQuoteRejected(quoteId);
      Logger.debug(`[Result] useQuoteRejected/updateQuoteRejected : `, result);
      error.value.updateQuoteRejected = null;
    } catch (err) {
      error.value.updateQuoteRejected = err;
      Logger.error(`useQuoteRejected/updateQuoteRejected`, err);
    } finally {
      loading.value = false;
    }

    return result;
  }

  return {
    update,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useQuoteRejected';
export default useQuoteRejected;
