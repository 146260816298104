












import { ref, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '~/composables';
import HTMLContent from '~/components/HTMLContent';

export default {
  name: 'PictimeFooterTunnel',
  components: {
    HTMLContent,
  },
  setup() {
    const { loadBlocks } = useContent();
    const blocks = ref([]);
    const blockReassurance = ref();

    useFetch(async () => {
      blocks.value = await loadBlocks({ identifiers: ['footer-reassurance', 'footer-logo', 'footer-redirect', 'footer-social', 'footer-additional', 'text-espace-part', 'popin-siret-content'] });
      blockReassurance.value = blocks.value[0].content;
    });

    return {
      blockReassurance,
    }
  },
};
