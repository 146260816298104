const middleware = {}

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['is-dirigeant-account'] = require('../middleware/is-dirigeant-account.ts')
middleware['is-dirigeant-account'] = middleware['is-dirigeant-account'].default || middleware['is-dirigeant-account']

middleware['is-guest'] = require('../middleware/is-guest.ts')
middleware['is-guest'] = middleware['is-guest'].default || middleware['is-guest']

middleware['is-sub-account'] = require('../middleware/is-sub-account.ts')
middleware['is-sub-account'] = middleware['is-sub-account'].default || middleware['is-sub-account']

export default middleware
