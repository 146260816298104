









































































































































import { storeToRefs } from 'pinia';
import { colorsValues as SF_COLORS } from '@storefront-ui/shared/variables/colors';
import { SfBadge, SfButton, SfCircleIcon, SfColor, SfColorPicker, SfDivider, SfIcon, SfImage, SfLoader, SfPrice, SfRating } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage';
import PictimeFlap from '../molecules/PictimeFlap';
import PictimePrice from '../molecules/PictimePrice';
import PictimeStock from '../molecules/PictimeStock';
import PriceLoader from '../atoms/PriceLoader';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import productPlaceholder from '@storefront-ui/shared/images/product_placeholder.svg';
import imgPlaceholder from '~/static/thankyou/visuel-produit-generique.svg';
import { onMounted, PropType, ref, watch } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import { Product } from '~/modules/catalog/product/types';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import { findItemOnWishlist } from '~/modules/wishlist/helpers/findItemOnWishlist';

export default {
  name: 'PictimeProductCard',
  components: {
    SfPrice,
    SfRating,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfBadge,
    SfButton,
    SfColorPicker,
    SfColor,
    SfDivider,
    SfLoader,
    SvgImage,
    PictimeFlap,
    PictimePrice,
    PictimeStock,
    PriceLoader
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    image: {
      type: [Array, Object, String],
      default: '',
    },
    badgeLabel: {
      type: String,
      default: '',
    },
    badgeColor: {
      type: String,
      default: '',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    shortDescription: {
      type: String,
      default: ''
    },
    isAuthenticated: {
      function: () => Promise <boolean>,
      default: false
    },
    oldPrice: {
      type: Number,
    },
    discountPercent: {
      type: [String, Number],
      default: '-20%'
    },
    discountAmount: {
      type: [String, Number],
      default: '-10€'
    },
    /**
     * Link element tag
     * @deprecated will be removed in 1.0.0 use slot to replace content
     */
    linkTag: {
      type: String,
      default: undefined,
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'heart',
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      default: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    flap: {
      type: String,
      default: ''
    },
    taxe: {
      type: String,
      default: ''
    },
    stocks: {
      type: Object,
      default: () => ({
        store: { isOk: false, label: 'Indisponible magasin' },
        delivery: { isOk: false, label: 'Indisponible livraison' }
      })
    },
    isPriceLoaded: {
      type: Boolean,
      default: false
    },
    priceLoading: {
      type: Boolean,
      default: true
    },
    unitMeasure: {
      type: String,
      default: ''
    },
    isCrossSell: {
      type: Boolean,
      default: false
    },
    isWishlistPopin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAddingToCart: false,
      openColorPicker: false,
      isPriceLoading: false,
      productPlaceholder,
      imgPlaceholder
    };
  },
  computed: {
    isSFColors() {
      return SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass() {
      return this.isSFColors ? `${this.badgeColor.trim()}` : '';
    },
  },
  setup(props) {
    const customerStore = useCustomerStore();
    const wishlistStore = useWishlistStore();
    const { currentShop } = storeToRefs(customerStore);
    const customerShopCode = ref();
    const iconWishlist = ref('');
    iconWishlist.value = props.wishlistIcon;

    const { toggleAddToCartSidebar, toggleAddToWishlistSidebar } = useUiState();
    const { getBestSimpleProduct, addItem, removeItem, loadById } = useWishlist();

    async function addToWishlist() {
      let baseProduct = props.product

      if (!baseProduct.sku) {
        return;
      }

      // On récupère les informations du produit simple
      const simpleProduct = await getBestSimpleProduct(baseProduct.sku as string)

      if (!simpleProduct) {
        return;
      }

      if (baseProduct.__typename === 'ConfigurableProduct') {
        baseProduct = Object.assign(baseProduct, {
          'configurable_product_options_selection': {
            'variant': {
              'sku': simpleProduct.sku
            }
          }
        })
      }

      baseProduct.image = {
        url: props.image,
        position: null,
        disabled: null,
        label: `Image produit ${props.product.name}`
      }

      baseProduct.priceExcludingTax.packagingFinalPrice = simpleProduct.price

      const data = {
        id: simpleProduct.sku,
        product: baseProduct,
        selectedOptionsUids: [simpleProduct.selectedOptionUid],
        enteredOption: [],
        articleCode: simpleProduct.articleCode,
        color: simpleProduct.color,
        conditioning: simpleProduct.conditioning,
        type: simpleProduct.type
      }

      // Dans le Xsell de la wishlist on n'affiche pas la popin
      if (props.isWishlistPopin) {
        const wishlistId = wishlistStore.selectedWishlistId

        if (iconWishlist.value === 'heart_fill') {
          // Supprime le produit des favoris
          const wishlist = await loadById({ id: wishlistId })
          const itemOnWishlist = findItemOnWishlist(
              [wishlist],
              wishlistId,
              { ...baseProduct, sku: simpleProduct.sku }
          )
          if (itemOnWishlist) {
            iconWishlist.value = 'heart'
            await removeItem(wishlistId, itemOnWishlist.id, {})
          }
        } else {
          // Ajoute le produit aux favoris
          iconWishlist.value = 'heart_fill'
          await addItem(wishlistId, {
            product: data.product,
            selectedOptionsUids: data.selectedOptionsUids,
            enteredOptions: data.enteredOption
          })
        }
      } else {
        wishlistStore.productAdded = data
        toggleAddToWishlistSidebar()
      }
    }

    onMounted(async () => {
      customerShopCode.value = customerStore.getCurrentShopCode;
    })

    watch(currentShop, () => {
      customerShopCode.value = customerStore.getCurrentShopCode;
    });

    return {
      customerShopCode,
      toggleAddToCartSidebar,
      addToWishlist,
      iconWishlist,
    }
  }
};
