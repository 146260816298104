<template>
  <div class="sf-header">
    <div class="sf-header__wrapper">
      <header ref="header" class="">
        <SfButton
          v-e2e="'app-header-store-locator'"
          aria-label="StoreLocator"
          class="sf-button--pure sf-header__action icon-store-locator smartphone-only"
          data-testid="storeLocatorIcon"
          @click="showStoreLocator"
        >
          <ShopNameInformation
            :show="true"
          />
        </SfButton>
        <div class="header-top">
          <div
            :class="{ 'is-visible': isNavVisible }"
            class="mega-menu-mobile smartphone-only"
          >
            <span v-if="!isMobileMenuOpen" class="sf-icon menu-mobile-open" @click="menuMobileOpen()">
              <SvgImage
                height="20"
                icon="Icon_menu"
                width="20"
              />
            </span>
            <span v-if="isMobileMenuOpen" class="sf-icon menu-mobile-close" @click="menuMobileClose()">
              <SvgImage
                height="20"
                icon="Icon_fermer_grise"
                width="20"
              />
            </span>
            <span v-if="isMobileMenuOpen" class="menu-mobile-content">
              <slot name="navigationmobile"></slot>
            </span>
          </div>
          <slot name="logo" v-bind="{ logo, title }">
            <SfLink link="/">
              <SfImage
                v-if="logo"
                :alt="title"
                :height="logoHeight"
                :src="logo"
                :width="logoWidth"
                class="sf-header__logo"
              />
              <h1 v-else class="sf-header__title">{{ title }}</h1>
            </SfLink>
          </slot>
          <div class="sf-header__aside">
            <slot name="aside"/>
          </div>
          <slot name="search" v-bind="{ searchValue, searchPlaceholder }">
          </slot>
          <slot
            name="header-icons"
            v-bind="{
              activeIcon,
              cartHasProducts,
              cartItemsQty,
              cartIcon,
              wishlistIcon,
              accountIcon,
            }"
          >
            <div class="sf-header__icons">
              <SfButton
                :class="{ 'display-none': !accountIcon }"
                aria-label="Account"
                class="sf-button--pure sf-header__action"
                data-testid="accountIcon"
                @click="$emit('click:account')"
              >
                <SfIcon
                  :class="{
                    'sf-header__icon is-active': activeIcon === 'account',
                  }"
                  :icon="accountIcon"
                  size="1.25rem"
                />
              </SfButton>
              <SfButton
                :class="{ 'display-none': !wishlistIcon }"
                aria-label="Wishlist"
                class="sf-button--pure sf-header__action"
                data-testid="wishlistIcon"
                @click="$emit('click:wishlist')"
              >
                <SfIcon
                  :badge-label="wishlistItemsQty"
                  :class="{
                    'sf-header__icon is-active': activeIcon === 'wishlist',
                  }"
                  :has-badge="wishlistHasProducts"
                  :icon="wishlistIcon"
                  class="sf-header__icon"
                  size="1.25rem"
                />
              </SfButton>
              <SfButton
                :class="{ 'display-none': !cartIcon }"
                aria-label="Cart"
                class="sf-button--pure sf-header__action"
                data-testid="cartIcon"
                @click="$emit('click:cart')"
              >
                <SfIcon
                  :badge-label="cartItemsQty"
                  :class="{
                    'sf-header__icon is-active': activeIcon === 'cart',
                  }"
                  :has-badge="cartHasProducts"
                  :icon="cartIcon"
                  class="sf-header__icon"
                  size="1.25rem"
                />
              </SfButton>
            </div>
          </slot>
        </div>
        <slot name="searchresult"></slot>
        <div class="mega-menu desktop-only">
          <nav
            :class="{ 'is-visible': isNavVisible }"
            class="mega-menu-navbar-container"
          >
            <slot name="navigationpromo"></slot>
            <slot name="navigation"></slot>
            <slot name="navigationlink"></slot>
          </nav>
        </div>
      </header>
    </div>
  </div>
</template>
<script>
import SfImage from "@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue";
import SfSearchBar from "@storefront-ui/vue/src/components/molecules/SfSearchBar/SfSearchBar.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";
import SfLink from "@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue";
import SvgImage from '~/components/General/SvgImage.vue';
import { useUiState } from '~/composables';
import ShopNameInformation from '~/components/ShopNameInformation';

export default {
  name: "SfHeader",
  components: {
    SfImage,
    SfSearchBar,
    SfButton,
    SfIcon,
    SfLink,
    SvgImage,
    ShopNameInformation
  },
  props: {
    logo: {
      type: [String, Object],
      default: "",
    },
    logoHeight: {
      type: Number,
      default: 35,
    },
    logoWidth: {
      type: Number,
      default: 34,
    },
    title: {
      type: String,
      default: "",
    },
    cartIcon: {
      type: [String, Boolean, Array],
      default: "empty_cart",
    },
    wishlistIcon: {
      type: [String, Boolean, Array],
      default: "heart",
    },
    accountIcon: {
      type: [String, Boolean, Array],
      default: "profile",
    },
    activeIcon: {
      type: String,
      default: "",
      validator(value) {
        return ["", "account", "wishlist", "cart"].includes(value);
      },
    },
    searchPlaceholder: {
      type: String,
      default: "Search for items",
    },
    searchValue: {
      type: String,
      default: "",
    },
    wishlistItemsQty: {
      type: [String, Number],
      default: "0",
    },
    cartItemsQty: {
      type: [String, Number],
      default: "0",
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    isNavVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: [],
      scrollDirection: null,
      lastScrollPosition: 0,
      animationStart: null,
      animationLong: null,
      animationDuration: 300,
    };
  },
  computed: {
    //...mapMobileObserver(),
    cartHasProducts() {
      return parseInt(this.cartItemsQty, 10) > 0;
    },
    wishlistHasProducts() {
      return parseInt(this.wishlistItemsQty, 10) > 0;
    },
  },
  methods: {
    showStoreLocator() {
      this.$parent.$emit('Cromology::StoreLocator::Open');
    },
  },
  setup() {
    const { isMobileMenuOpen, toggleMobileMenu } = useUiState();

    const menuMobileOpen  = () => {
      toggleMobileMenu();
      document.body.classList.add('no-scroll');
    };
    const menuMobileClose = () => {
      toggleMobileMenu();
      document.body.classList.remove('no-scroll');
    };

    return {
      isMobileMenuOpen,
      menuMobileOpen,
      menuMobileClose,
    };
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfHeader.scss";
@import "@/assets/theme/default/scss/components/header";
</style>
