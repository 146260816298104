import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseCurrentShopErrors, UseCurrentShopInterface } from './useCurrentShop';
import { useCart } from '~/modules/checkout/composables/useCart';

export function useCurrentShop(): UseCurrentShopInterface {
  const { app } = useContext();
  const context = app.$vsf;
  const { load: loadCart } = useCart();

  const loading = ref(false);
  const error = ref<UseCurrentShopErrors>({
    postCurrentShop: null
  });

  const apiState = context.$magento.config.state;

  const set = async (currentShopCode) => {

    Logger.debug(`useCurrentShop/postCurrentShop`, currentShopCode);
    let currentShop = null;

    try {
      const token = app.$cookies.get('shop_token_id', { parseJSON: false });
      const tokenId = token ? token : '';

      if (!apiState.getCartId()) {
        await loadCart({ realCart: true });
      }
      const cartId = apiState.getCartId();

      loading.value = true;

      // @ts-ignore @TODO add establishment to ApiClientMethods
      currentShop = await app.$vsf.$magento.api.setCurrentShop(currentShopCode, tokenId, cartId);

      if (currentShop?.data?.setCurrentShop?.token_id) {
        app.$cookies.set('shop_token_id', currentShop.data.setCurrentShop.token_id, {
          path: '/',
          maxAge: 60 * 60 * 24
        })
      }

      if (currentShop?.data?.setCurrentShop?.cart_id) {
        if (cartId != currentShop?.data?.setCurrentShop?.cart_id) {
          await loadCart({ realCart: true });
        }
      }

      Logger.debug(`[Result] useCurrentShop/postCurrentShop : `, currentShop);

      error.value.postCurrentShop = null;
    } catch (err) {
      error.value.postCurrentShop = err;
      Logger.error(`useCurrentShop/currentShop`, err);
    } finally {
      loading.value = false;
    }

    return currentShop.data.setCurrentShop;
  };

  const get = async () => {

    let currentShop = null;

    try {
      const token = app.$cookies.get('shop_token_id', { parseJSON: false });
      const tokenId = token ? token : '';

      loading.value = true;

      // @ts-ignore @TODO add establishment to ApiClientMethods
      currentShop = await app.$vsf.$magento.api.getCurrentShop(tokenId);

      if (currentShop.data.getCurrentShop.token_id) {
        app.$cookies.set('shop_token_id', currentShop.data.getCurrentShop.token_id, {
          path: '/',
          maxAge: 60 * 60 * 24
        })
      }

      Logger.debug(`[Result] useCurrentShop/postCurrentShop : `, currentShop);

      error.value.postCurrentShop = null;
    } catch (err) {
      error.value.postCurrentShop = err;
      Logger.error(`useCurrentShop/currentShop`, err);
    } finally {
      loading.value = false;
    }

    return currentShop.data.getCurrentShop;
  };

  return {
    set,
    get,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useCurrentShop';
export default useCurrentShop;
