import { computed, onMounted, ref, useContext } from '@nuxtjs/composition-api';
import { Role } from '~/modules/GraphQL/types';

const defaultRole: Role = {
  role: '',
  is_view_price: true,
  is_view_order: false,
  is_view_invoices: false,
  is_view_quotations: false,
  is_view_outstanding: false,
  is_view_listing: false,
  is_validate_order: false,
  ceiling_validated_order: 0,
  is_notification_order: false,
  is_pay_cd: false,
  is_pay_outstanding: false
};

const role = ref(defaultRole);

const getRole = () => {
  const context = useContext();
  const isLogin = context.app.$vsf.$magento.config.state.getCustomerToken();
  const customerRole = context.app.$vsf.$cromology.config.state.getCustomerRole();
  role.value = isLogin && customerRole ? customerRole : role.value;
};

export const QUOTE_STATUS_WAITING_FOR_APPROVAL = 'waiting_for_approval';
export const QUOTE_STATUS_APPROVED = 'approved';

export const DIRIGEANT_LABEL = 'Dirigeant';
export const SUBACCOUNT_LABEL = 'Subaccount';
export const COMMERCIAL_LABEL = 'Commercial';

export const SUBACCOUNT_INACTIVE = 0;
export const SUBACCOUNT_ACTIVE = 1;
export const SUBACCOUNT_PENDING = 2;

export const IS_VIEW_ORDER_NOT_ALLOWED = 0;
export const IS_VIEW_ORDER_ONLY_SUBACCOUNT = 1;
export const IS_VIEW_ORDER_ALL = 2;

export const cachePrice = () => {
  onMounted(() => getRole());

  const canViewPrice = computed(() => {
    return role.value.is_view_price;
  })

  return { canViewPrice };
}

export const cacheRole = () => {
  onMounted(() => getRole());

  const isDirigeant = computed(() => {
    return role.value.role === DIRIGEANT_LABEL;
  })

  const isSubAccount = computed(() => {
    return role.value.role === SUBACCOUNT_LABEL;
  })

  const getCustomerRole = computed(() => {
    return role.value;
  })

  const isCommercial = computed(() => {
    return role.value.role === COMMERCIAL_LABEL;
  })

  const isPayCB = computed(() => {
    return role.value.is_pay_cd;
  })

  return { isDirigeant, isSubAccount, getCustomerRole, isCommercial, isPayCB };
}

export const cacheQuote = () => {
  onMounted(() => getRole());
  const { isSubAccount } = cacheRole()

  const hasApprovalOrder = (cart) => {
    const result = ref(false);

    if (isSubAccount.value) {
      if (cart.id) {
        result.value = (cart?.status === QUOTE_STATUS_WAITING_FOR_APPROVAL || cart?.status === QUOTE_STATUS_APPROVED)
          && cart?.waiting_for_approval;
      }
    }
    return result.value;
  }

  return { hasApprovalOrder }
}
