import type { Ref } from '@nuxtjs/composition-api';
import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseSearchErrors, UseSearchInterface } from '~/composables/useSearch/useSearch';
import { SearchData } from '~/custom-api/api/search/types';

export function useSearch(): UseSearchInterface {
  const loading: Ref<boolean> = ref(false);
  const error: Ref<UseSearchErrors> = ref({
    search: null
  });
  const { app } = useContext();

  const search = async (q): Promise<SearchData[]> => {
    Logger.debug('useSearch/search');
    error.value.search = null;

    try {
      loading.value = true;
      const { data } = await app.$vsf.$magento.api.search(q);
      return data.search ? data.search : [];
    } catch (e) {
      error.value.search = e;
    } finally {
      loading.value = false;
    }
  };

  return {
    search: search,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useSearch;
export * from './useSearch';
