import { defineStore } from 'pinia';
import type { Wishlist } from '~/modules/GraphQL/types';
import { ProductConfiguration, WishlistProductAdded, Wishlists } from '~/modules/wishlist/types/types';

interface WishlistState {
  wishlists: Array<Wishlist & { configurable_products_configuration?: ProductConfiguration }>,
  wishlistsNames: Wishlists,
  selectedWishlistId: string
  productAdded?: WishlistProductAdded
}

export const useWishlistStore = defineStore('wishlist', {
  state: (): WishlistState => ({
    wishlists: [],
    wishlistsNames: [],
    selectedWishlistId: '',
    productAdded: undefined
  }),

  getters: {
    getSelectedWishlistId: (state: WishlistState) => state.selectedWishlistId,
  },

  actions: {
    setProductAdded(product) {
      this.productAdded = product
    }
  },
});
