













import { SfSidebar } from '@storefront-ui/vue';
import { defineComponent, } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'StoreSidebar',
  components: {
    SfSidebar,
  },
  setup() {
    const { isStoreSidebarOpen, toggleStoreSidebar } = useUiState();

    return {
      isStoreSidebarOpen,
      toggleStoreSidebar
    };
  },
});
