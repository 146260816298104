import { Middleware } from '@nuxt/types';
import { DIRIGEANT_LABEL } from '~/helpers/customer/role';

const middleware: Middleware = (context) => {
  const homepageURL = context.localeRoute({ name: 'home' });
  const accountURL = context.localeRoute({ name: 'customer-my-profile' });

  const isLoggedIn = context.app.$vsf.$magento.config.state.getCustomerToken();
  const customerRole = context.app.$vsf.$cromology.config.state.getCustomerRole();

  if (!isLoggedIn) {
    context.redirect(homepageURL);
  } else if (customerRole?.role !== DIRIGEANT_LABEL) {
    context.redirect(accountURL);
  }
};

export default middleware;
