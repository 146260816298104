import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useContext } from '@nuxtjs/composition-api';

export const customerShopCode = () => {
  const customerStore = useCustomerStore();
  const { app } = useContext();

  const setCustomerShopCode = (shopCode: string = '') => {
    customerStore.setCurrentShopCode(shopCode);

    let expiry = new Date()
    expiry.setMonth(expiry.getMonth() + 6)
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('favoriteStore');
      localStorage.setItem(
        'favoriteStore',
        JSON.stringify({
          value: shopCode,
          expiry: expiry.getTime()
        })
      )
    }
  }

  const unsetCustomerShopCode = () => {
    customerStore.setCurrentShopCode('');
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('favoriteStore');
    }
    app.$cookies.remove('shop_token_id');
  }

  return {
    setCustomerShopCode,
    unsetCustomerShopCode
  };
};
