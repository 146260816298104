import {readonly, Ref, ref, useContext} from '@nuxtjs/composition-api';
import { findItemOnWishlist } from '~/modules/wishlist/helpers/findItemOnWishlist';
import { Logger } from '~/helpers/logger';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { Wishlist } from '~/modules/GraphQL/types';
import type {
  UseWishlistAddItemParams,
  UseWishlistErrors,
  UseWishlistInterface,
  UseWishlistIsInWishlistParams,
  UseWishlistLoadByIdParams,
  UseWishlistLoadParams,
  UseWishlistRemoveItemParams,
} from '~/modules/wishlist/composables/useWishlist/useWishlist';
import { MutationUpdateProductsInWishlistArgs } from '~/modules/GraphQL/types';
import { SimpleProduct, Wishlists } from '~/modules/wishlist/types/types';
import { findWishlistWithID } from '~/modules/wishlist/helpers/findWishlistWithID';
import { AddProductsToWishlistMutation } from '@vue-storefront/magento-api';

const loading: Ref<boolean> = ref(false);

/**
 * Allows loading and manipulating wishlist of the current user.
 *
 * See the {@link UseWishlistInterface} for a list of methods and values available in this composable.
 */
export function useWishlist(): UseWishlistInterface {
  const wishlistStore = useWishlistStore();
  const { app } = useContext();
  const apiState = app.$vsf.$magento.config.state;

  const error = ref<UseWishlistErrors>({
    clear: null,
    getWishlists: null,
    addWishlist: null,
    updateWishlist: null,
    deleteWishlist: null,
    addItem: null,
    removeItem: null,
    update: null,
    load: null,
    loadById: null,
    getBestSimpleProduct: null,
  });

  const clear = () => {
    Logger.debug('useWishlist/clear');

    try {
      loading.value = true;
      error.value.clear = null;

      wishlistStore.$patch((state) => {
        state.wishlists = [];
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useWishlist/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const setWishlists = (newWishlists: [Wishlist]) => {
    wishlistStore.wishlists = newWishlists;
    Logger.debug('useWishlist/setWishlists', newWishlists);
  };

  const getWishlists = async (): Promise<Wishlists> => {
    let res: Wishlists = [];
    Logger.debug('useWishlist/getWishlists');

    try {
      loading.value = true;
      error.value.getWishlists = null;
      Logger.debug('[Magento Storefront]: useWishlist.getWishlists');

      if (apiState.getCustomerToken()) {
        res = wishlistStore.wishlistsNames;

        if (!res.length) {
          const { data } = await app.$vsf.$magento.api.wishlist({}, { wishlist: 'customGetWishlists' });
          Logger.debug('[Result]:', { data });
          res = data?.customer?.wishlists || [];

          wishlistStore.wishlistsNames = res;
          clear();
        }
      }
    } catch (err) {
      error.value.getWishlists = err;
      Logger.error('useWishlist/getWishlists', err);
    } finally {
      loading.value = false;
    }

    return res;
  };

  const addWishlist = async (name: string): Promise<Wishlist> => {
    Logger.debug('useWishlist/addWishlist');

    try {
      loading.value = true;
      error.value.addWishlist = null;
      Logger.debug('[Magento Storefront]: useWishlist.addWishlist params ->', name);

      if (apiState.getCustomerToken()) {
        const { data } = await app.$vsf.$magento.api.addWishlist(name);
        Logger.debug('[Result]:', { data });
        const res = data?.addWishlist;

        if (res) {
          wishlistStore.wishlistsNames.push(res);
          clear();
        }
        return res;
      }
    } catch (err) {
      error.value.addWishlist = err;
      Logger.error('useWishlist/addWishlist', err);
    } finally {
      loading.value = false;
    }
  };

  const deleteWishlist = async (id: string): Promise<boolean> => {
    Logger.debug('useWishlist/deleteWishlist');

    try {
      loading.value = true;
      error.value.deleteWishlist = null;
      Logger.debug('[Magento Storefront]: useWishlist.addWishlist deleteWishlist ->', id);

      if (apiState.getCustomerToken()) {
        const { data } = await app.$vsf.$magento.api.deleteWishlist(id);
        Logger.debug('[Result]:', { data });
        const res = data?.deleteWishlist;

        if (res) {
          wishlistStore.wishlistsNames = wishlistStore.wishlistsNames.filter((el) => el.id !== id);
          clear();
        }

        return res;
      }

    } catch (err) {
      error.value.deleteWishlist = err;
      Logger.error('useWishlist/deleteWishlist', err);
    } finally {
      loading.value = false;
    }
  };

  const updateWishlist = async (id: string, name: string): Promise<Wishlist> => {
    Logger.debug('useWishlist/updateWishlist');
    error.value.updateWishlist = null;

    try {
      loading.value = true;
      error.value.updateWishlist = null;
      Logger.debug('[Magento Storefront]: useWishlist.updateWishlist params ->', id, name);

      if (apiState.getCustomerToken()) {
        const { data } = await app.$vsf.$magento.api.updateWishlist(id, name);
        Logger.debug('[Result]:', { data });
        const res = data?.updateWishlist;

        if (res?.name) {
          wishlistStore.wishlistsNames = wishlistStore.wishlistsNames.map((el) => {
            if (el.id === id) {
              el.name = name;
            }

            return el;
          });

          clear();

          return res;
        }
      }
    } catch (err) {
      error.value.updateWishlist = err;
      Logger.error('useWishlist/updateWishlist', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async (wishlistId: string, { product, selectedOptionsUids, enteredOptions, customQuery }: UseWishlistAddItemParams) => {
    Logger.debug('useWishlist/addItem', product);

    try {
      loading.value = true;
      error.value.addItem = null;

      Logger.debug('[Magento Storefront]: useWishlist.addItem params->', {
        wishlistId, product, selectedOptionsUids, enteredOptions, customQuery
      });

      let res: AddProductsToWishlistMutation = {};

      if (!apiState.getCustomerToken()) {
        Logger.error('Need to be authenticated to add a product to wishlist');
      }

      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      switch (product.__typename) {
        case 'VirtualProduct':
        case 'DownloadableProduct':
        case 'GroupedProduct':
        case 'GiftCard':
        case 'SimpleProduct': {
          const { data: simpleProductData } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: wishlistId,
            items: [{
              sku: product.sku,
              quantity: 1,
            }],
          }, customQuery);

          Logger.debug('[Result]:', { simpleProductData });

          res = simpleProductData;

          break;
        }
        case 'ConfigurableProduct': {
          const { data: configurableProductData } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: wishlistId,
            items: [{
              sku: product.configurable_product_options_selection?.variant?.sku || product.sku,
              quantity: 1,
              parent_sku: product.sku,
              selected_options: selectedOptionsUids,
              entered_options: enteredOptions
            }],
          }, customQuery);

          Logger.debug('[Result]:', { data: configurableProductData });

          res = configurableProductData;

          break;
        }
        case 'BundleProduct': {
          const { data: bundleProductData } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: wishlistId,
            items: [{
              sku: product.sku,
              quantity: 1,
              entered_options: enteredOptions
            }],
          }, customQuery);

          Logger.debug('[Result]:', { data: bundleProductData });

          res = bundleProductData;

          break;
        }
        default:
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          Logger.error(`Product Type ${product.__typename} not supported in add to wishlist yet`);
          break;
      }

      // On delete du store la wishlist dans lequel le produit a été ajouté pour un reload complet
      if (res?.addProductsToWishlist?.wishlist) {
        wishlistStore.$patch((state) => {
          state.wishlists.map((wishlist, index) => {
            if (wishlist.id === wishlistId) {
              state.wishlists.splice(index, 1);
            }
          })
        });
      }
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useWishlist/addItem', err);
    } finally {
      loading.value = false;
    }
  };

  const removeItem = async (wishlistId: string, itemId: string, { customQuery }: UseWishlistRemoveItemParams) => {
    Logger.debug('useWishlist/removeItem', itemId);

    try {
      loading.value = true;
      error.value.removeItem = null;
      Logger.debug('[Magento Storefront]: useWishlist.removeItem params->', {
        wishlistId, itemId
      });

      const { data } = await app.context.$vsf.$magento.api.removeProductsFromWishlist({
        id: wishlistId,
        items: [itemId],
      }, customQuery);

      Logger.debug('[Result]:', { data });

      // Supprime l'item dans le store
      wishlistStore.$patch((state) => {
        state.wishlists.map((wishlist) => {
          if (wishlist.id === wishlistId) {
            wishlist.items_v2.items = wishlist.items_v2.items.filter((item) => item.id !== itemId)
          }
        })
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useWishlist/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItem = async (params: MutationUpdateProductsInWishlistArgs): Promise<void> => {
    Logger.debug('useWishlist/updateItem');

    try {
      error.value.update = null;

      Logger.debug('[Magento Storefront]: useWishlist.updateItem params->', params);

      if (apiState.getCustomerToken()) {
        await app.$vsf.$magento.api.updateProductsInWishlist(params)
      }
    } catch (err) {
      error.value.update = err;
      Logger.error('useWishlist/updateItem', err);
    }
  }

  const isInWishlist = (wishlistId: string, item: UseWishlistIsInWishlistParams) => {
    Logger.debug('useWishlist/isInWishlist', item);

    const wishlistProduct = findItemOnWishlist(wishlistStore.wishlists, wishlistId, item.product);

    return !!(wishlistProduct?.id && wishlistProduct?.quantity);
  };

  const load = async (params?: UseWishlistLoadParams): Promise<[Wishlist]> => {
    Logger.debug('useWishlist/load');

    try {
      loading.value = true;
      Logger.debug('[Magento Storefront]: useWishlist.load params->', params);

      if (apiState.getCustomerToken()) {
        const { data } = await app.$vsf.$magento.api.wishlist(params?.searchParams, params?.customQuery);

        Logger.debug('[Result]:', { data });

        const loadedWishlists = data?.customer?.wishlists ?? [];
        setWishlists(loadedWishlists);
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useWishlist/load', err);
    } finally {
      loading.value = false;
    }

    return wishlistStore.wishlists;
  };

  const loadById = async (params: UseWishlistLoadByIdParams): Promise<Wishlist> => {
    Logger.debug('useWishlist/loadById');

    let wishlist = undefined;

    try {
      loading.value = true;

      Logger.debug('[Magento Storefront]: useWishlist.loadById params->', params);

      if (apiState.getCustomerToken()) {
        wishlist = findWishlistWithID(wishlistStore.wishlists, params.id);

        if (!wishlist) {
          const { data } = await app.$vsf.$magento.api.getWishlist(
            { id: params.id, searchParams: params?.searchParams },
            params?.customQuery
          );

          Logger.debug('[Result]:', { data });

          const loadedWishlist = data?.customer?.wishlist_v2;

          if (loadedWishlist) {
            wishlist = loadedWishlist;
            wishlistStore.wishlists.push(wishlist);
          }
        }
      }

      error.value.loadById = null;
    } catch (err) {
      error.value.loadById = err;
      Logger.error('useWishlist/loadById', err);
    } finally {
      loading.value = false;
    }

    return wishlist;
  };

  const getBestSimpleProduct = async (id: string): Promise<?SimpleProduct> => {
    Logger.debug('useWishlist/getBestSimpleProduct');

    try {
      loading.value = true;
      Logger.debug('[Magento Storefront]: useWishlist.getBestSimpleProduct id->', id);

      if (apiState.getCustomerToken()) {
        const { data } = await app.$vsf.$magento.api.getBestSimpleProduct(id);
        Logger.debug('[Result]:', { data });

        return data?.getBestSimpleProduct
      }

      error.value.getBestSimpleProduct = null;
    } catch (err) {
      error.value.getBestSimpleProduct = err;
      Logger.error('useWishlist/getBestSimpleProduct', err);
    } finally {
      loading.value = false;
    }

    return undefined;
  };

  return {
    setWishlists,
    clear,
    getWishlists,
    addWishlist,
    deleteWishlist,
    updateWishlist,
    addItem,
    removeItem,
    updateItem,
    isInWishlist,
    load,
    loadById,
    getBestSimpleProduct,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useWishlist;
export * from './useWishlist';
