











































































import { SfButton, SfDivider, SfImage, SfSidebar } from '@storefront-ui/vue';
import { defineComponent, useContext, useRouter } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import productPlaceholder from '@storefront-ui/shared/images/product_placeholder.svg';
import { cachePrice } from '~/helpers/customer/role.ts';
import XsellProducts from '~/modules/catalog/product/components/XsellProducts.vue';

export default defineComponent({
  name: 'AddToWishlistRecap',
  components: {
    SfSidebar,
    SfButton,
    SvgImage,
    SfImage,
    SfDivider,
    XsellProducts
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    articleCode: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    conditioning: {
      type: String,
      default: ''
    },
    quantity: {
      type: Number,
      default: 1
    },
    total: {
      type: [String, Number, Boolean],
      default: 0
    },
    taxe: {
      type: String,
      default: 'TTC'
    },
    image: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'Peintures pro'
    },
  },
  setup() {
    const { toggleAddToWishlistSidebar } = useUiState();
    const router = useRouter();
    const { app } = useContext();
    const { canViewPrice } = cachePrice();

    const goToWishlist = async () => {
      await router.push(app.localeRoute({ name: 'customer-my-wishlist' }));
      toggleAddToWishlistSidebar();
    }

    return {
      toggleAddToWishlistSidebar,
      productPlaceholder,
      goToWishlist,
      canViewPrice
    }
  }
});
