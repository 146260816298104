






























import { SfButton, SfImage, SfSidebar, } from '@storefront-ui/vue';
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import AddToCartRecap from '~/components/molecules/AddToCartRecap.vue';
import AddToWishlistRecap from '~/modules/wishlist/components/AddToWishlistRecap.vue';
import SelectWishlist from '~/modules/wishlist/components/SelectWishlist.vue';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';

export default defineComponent({
  name: 'AddToWishlistSidebar',
  components: {
    SelectWishlist,
    AddToWishlistRecap,
    SfSidebar,
    SfButton,
    SvgImage,
    SfImage,
    AddToCartRecap
  },
  setup() {
    const { isAddToWishlistSidebarOpen, toggleAddToWishlistSidebar } = useUiState();
    const { error: wishlistError, addWishlist, addItem } = useWishlist();
    const addToWishlistError = computed(() => wishlistError.value?.addItem?.message);
    const wishlistStore = useWishlistStore()

    const showSelectWishlist = ref(true);
    const showRecapWishlist = ref(false);

    async function addToWishlist(wishlistId, newWishlistName) {
      if (wishlistId === -1) {
        const wishlist = await addWishlist(newWishlistName)
        wishlistId = wishlist.id
      }

      wishlistStore.selectedWishlistId = wishlistId

      await addItem(wishlistId, {
        product: wishlistStore.productAdded.product,
        selectedOptionsUids: wishlistStore.productAdded.selectedOptionsUids,
        enteredOptions: wishlistStore.productAdded.enteredOptions
      })

      if (!addToWishlistError.value) {
        showSelectWishlist.value = false;
        showRecapWishlist.value = true;
      }
    }

    return {
      isAddToWishlistSidebarOpen,
      toggleAddToWishlistSidebar,
      showSelectWishlist,
      showRecapWishlist,
      addToWishlist,
      addToWishlistError,
      wishlistStore,
    };
  },
});
