







import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { parseFromString } from 'dom-parser';

export default defineComponent({
  name: 'HTMLContent',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    content: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const isAlreadyLoaded = ref(false);

    const execScript = () => {
      if (isAlreadyLoaded.value) {
        return;
      }

      const doc = parseFromString(props.content);
      const scripts = doc.getElementsByTagName('script');

      if (scripts) {
        scripts.map((script) => eval(script.innerHTML));
      }

      isAlreadyLoaded.value = true;
    }

    onMounted(execScript);
  },
});
