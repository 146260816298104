import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseProductAttributeDataErrors, UseProductAttributeDataInterface } from './useProductAttributeData';

export function useProductAttributeData(): UseProductAttributeDataInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseProductAttributeDataErrors>({
    getProductAttributeData: null
  });

  const search = async (sku) => {
    Logger.debug(`useProductAttributeData/getProductAttributeData/sku =`, sku);
    let productAttributeData = null;
    try {
      loading.value = true;

      // @ts-ignore @TODO add establishment to ApiClientMethods
      productAttributeData = await app.$vsf.$magento.api.productAttributeData(sku, sku?.customQuery ?? null);
      error.value.getProductAttributeData = null;
    } catch (err) {
      error.value.getProductAttributeData = err;
      Logger.error(`useProductAttributeData/getProductAttributeData`, err);
    } finally {
      loading.value = false;
    }

    return productAttributeData;
  }

  return {
    search,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useProductAttributeData';
export default useProductAttributeData;
