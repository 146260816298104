import { Middleware } from '@nuxt/types';

const middleware: Middleware = (context) => {
  const token = context.app.$cookies.get('persistent_token', { parseJSON: false });
  const persistentToken = token ? token : '';
  if (!context.app.$vsf.$magento.config.state.getCustomerToken() && !persistentToken) {
    const homepageURL = context.localeRoute({ name: 'home' });
    context.redirect(homepageURL);
  }
};
export default middleware;
