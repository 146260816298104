import { defineStore } from 'pinia';
import type { Customer } from '~/modules/GraphQL/types';

interface CustomerState {
  user: Customer | null,
  isLoggedIn: boolean,
  currentShop: string,
  showOrderApprovalValidationMessage: boolean,
  showNoMoreOrdersToValidate: boolean,
  showOrderApprovalValidationMessageWithModification: boolean,
  showOrderApprovalRejected: boolean,
  hasTriedToAutoconnect: boolean,
  passwordChanged: boolean,
  subAccountIdToDelete: number,
  orderIdToReject: number,
}

export const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    user: null,
    isLoggedIn: false,
    currentShop: '',
    showOrderApprovalValidationMessage: false,
    showNoMoreOrdersToValidate: false,
    showOrderApprovalValidationMessageWithModification: false,
    showOrderApprovalRejected: false,
    hasTriedToAutoconnect: false,
    passwordChanged: false,
    subAccountIdToDelete: 0,
    orderIdToReject: 0,
  }),
  getters: {
    getCurrentShopCode: (state) => state.currentShop,
    getIsLoggedIn: (state) => state.isLoggedIn,
    getHasTriedToAutoconnect: (state) => state.hasTriedToAutoconnect,
    getPasswordChanged: (state) => state.passwordChanged,
    getSubAccountIdToDelete: (state) => state.subAccountIdToDelete,
    getOrderIdToReject: (state) => state.orderIdToReject,
  },
  actions: {
    setUser(user: Customer | null) {
      this.user = user;
    },
    setIsLoggedIn(isLoggedIn: boolean) {
      this.isLoggedIn = isLoggedIn;
    },
    setCurrentShopCode(currentShop: string) {
      this.currentShop = currentShop;
    },
    setShowOrderApprovalValidationMessage(status) {
      this.showOrderApprovalValidationMessage = status;
    },
    setShowNoMoreOrdersToValidate(status) {
      this.showNoMoreOrdersToValidate = status;
    },
    setShowOrderApprovalValidationMessageWithModification(status) {
      this.showOrderApprovalValidationMessageWithModification = status;
    },
    setShowOrderApprovalRejected(status) {
      this.showOrderApprovalRejected = status;
    },
    setHasTriedToAutoconnect(status) {
      this.hasTriedToAutoconnect = status;
    },
    setSubAccountIdToDelete(id): void {
      this.subAccountIdToDelete = id;
    },
    setOrderIdToReject(id): void {
      this.orderIdToReject = id;
    }
  },
});
