import type { Ref } from '@nuxtjs/composition-api';
import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseXsellProductsError, UseXsellProductsInterface, UseXsellProductsSearchParams, } from './useXsellProducts';

/**
 * Allows loading upsell products.
 *
 * See the {@link UseXsellProductsInterface} for a list of methods and values available in this composable.
 */
export function useXsellProducts(): UseXsellProductsInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error: Ref<UseXsellProductsError> = ref({
    search: null,
  });

  const search = async (params: UseXsellProductsSearchParams) => {
    const {
      customQuery,
      ...searchParams
    } = params;

    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] Load upsell products based on ', { searchParams });

      const { data } = await app
        .$vsf
        .$magento
        .api
        .xsellProducts(searchParams, params?.customQuery ?? null);

      Logger.debug('[Result] Xsell products:', { data });

      results = data.products?.items[0]?.crosssell_products;
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useXsellProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    search,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useXsellProducts;
export * from './useXsellProducts';
