import type { Ref } from '@nuxtjs/composition-api';
import { readonly, ref, useContext, } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseShopErrors, UseShopInterface, UseShopLoadParams, } from './useShop';


/**
 * Allows fetching shop.
 *
 * See the `UseShopInterface` for a list of methods and values available in this composable.
 */
export function useShop(): UseShopInterface {
  const { app } = useContext();
  const loading: Ref<boolean> = ref(false);
  const errorsFactory = (): UseShopErrors => ({
    load: null,
  });
  const error: Ref = ref(errorsFactory());

  const load = async (params: UseShopLoadParams) => {
    Logger.debug('[Magento] useShop.load', params);
    const { storeCode } = params;
    let results = null;

    try {
      loading.value = true;
      error.value.load = null;
      if (storeCode) {
        const { data } = await app.context.$vsf.$magento.api.store({ storeCode }, params?.customQuery ?? null);

        Logger.debug('[Result]:', { data });
        results = data?.store ?? null;
      }
    } catch (err) {
      error.value.load = err;
      Logger.error('useShop/load', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    load,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export * from './useShop';
export default useShop;
