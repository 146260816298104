




































import { SfButton, SfSidebar, } from '@storefront-ui/vue';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useUiState, useWishlist } from '~/composables';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'RenameWishlistSidebar',
  components: {
    SvgImage,
    SfSidebar,
    SfButton,
  },
  props: {},
  setup() {
    const { updateWishlist } = useWishlist();
    const { isRenameWishlistSidebarOpen, toggleRenameWishlistSidebar } = useUiState();
    const wishlistStore = useWishlistStore();

    const newWishlistName = ref('');

    const onUpdateWishlist = async () => {
      await updateWishlist(wishlistStore.getSelectedWishlistId, newWishlistName.value);
      toggleRenameWishlistSidebar()
    }

    return {
      onUpdateWishlist,
      newWishlistName,
      isRenameWishlistSidebarOpen,
      toggleRenameWishlistSidebar,
    };
  },
});
