import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseProductFeatureError, UseProductFeatureInterface } from './useGetProductFeature';

export function useGetProductFeature(): UseProductFeatureInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseProductFeatureError>({
    getFeatureError: null
  });

  const search = async (featureId) => {
    Logger.debug(`useGetProductFeature/search`);
    let featureContent = null;

    try {
      loading.value = true;

      // @ts-ignore @TODO add establishment to ApiClientMethods
      featureContent = await app.$vsf.$magento.api.getProductFeature(featureId);

      Logger.debug(`[Result] useGetProductFeature/search : `, featureContent);

      error.value.getFeatureError = null;
    } catch (err) {
      error.value.getFeatureError = err;
      Logger.error(`useGetProductFeature/search`, err);
    } finally {
      loading.value = false;
    }

    return featureContent;
  }

  return {
    search,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useGetProductFeature';
export default useGetProductFeature;
