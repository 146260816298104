import { Wishlist, WishlistItemInterface } from '~/modules/GraphQL/types';
import { productMatch } from '~/modules/wishlist/helpers/productMatch';
import { findWishlistWithID } from '~/modules/wishlist/helpers/findWishlistWithID';
import { Product } from '~/modules/catalog/product/types';
import { WishlistItemInterfaceExtended } from '~/modules/wishlist/types/types';

export const findItemOnWishlist = (wishlists: [Wishlist], wishlistId: string, product: Product): WishlistItemInterface|undefined => {
  const wishlist = findWishlistWithID(wishlists, wishlistId)

  if (wishlist?.id) {
    return wishlist
      ?.items_v2
      ?.items?.find((item: WishlistItemInterfaceExtended) => productMatch(item, product))
  }

  return undefined;
};
