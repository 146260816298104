























import { defineComponent, onMounted, useRoute } from '@nuxtjs/composition-api';
import PictimeHeaderTunnel from '~/components/organisms/PictimeHeaderTunnel';
import PictimeFooterTunnel from '~/components/organisms/PictimeFooterTunnel';
import IconSpriteTollens from '~/components/General/IconSpriteTollens';
import IconSpriteZolpan from '~/components/General/IconSpriteZolpan';
import { useCurrentShop, useUiState, useUser } from '~/composables';
import StoreSidebar from '~/components/StoreSidebar';
import CromologyStoreLocator from '~/components/CromologyStoreLocator';
import { useUser } from '~/modules/customer/composables/useUser';
import { customerShopCode } from '~/modules/customer/helpers/customerShopCode';

export default defineComponent({
  name: 'checkoutOrdered',
  components: {
    PictimeHeaderTunnel,
    IconSpriteTollens,
    IconSpriteZolpan,
    PictimeFooterTunnel,
    StoreSidebar,
    CromologyStoreLocator,
  }, methods: {
    showStoreLocator() {
      this.$emit('Cromology::StoreLocator::Open');
    },
  },
  setup() {
    const route = useRoute();
    const { isStoreSidebarOpen } = useUiState();

    const { isAuthenticated } = useUser();
    const { get: getCurrent } = useCurrentShop();
    const { setCustomerShopCode } = customerShopCode();

    onMounted(async () => {
      await isAuthenticated();
      const current = await getCurrent();
      setCustomerShopCode(current.current_shop);
    })

    return {
      route,
      isStoreSidebarOpen,
    };
  },
  data() {
    return {
      openLocator: false,
    }
  },
});
