const calculFlap = (isAuth, product) => {
  if (product.priceExcludingTax || product.priceIncludingTax) {
    if (product.priceExcludingTax.priceType == 'SPECIAL' && product.priceExcludingTax.discountPercentage > 0) {
      return 'remise'
    } else if (product.priceExcludingTax.priceType == 'PROMOTION' || product.priceIncludingTax.priceType == 'PROMOTION') {
      return 'promo'
    } else if (product.priceExcludingTax.priceType == 'DESTOCK' || product.priceIncludingTax.priceType == 'DESTOCK') {
      return 'destock'
    }
  }
  if (product?.product?.is_new_product || product?.is_new_product) {
    return 'nouveau'
  }
}

export default calculFlap;
