



















































import { computed, defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import { useUser } from '~/modules/customer/composables/useUser';
import useGetActiveMessages from '~/modules/customer/composables/useGetActiveMessages';
import { activeMessage } from '~/helpers/customer/messageHelper';
import { cacheRole } from '~/helpers/customer/role.ts';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'MessageBanner',
  components: {
    SvgImage,
    SfLink
  },
  props: {
    showRejectCart: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const { isAuthenticated, user, load } = useUser();
    const isAuth                          = ref(false);
    const { loadMessage }                 = useGetActiveMessages();
    const msg                             = ref({ content: '' });
    const { getMessage }                  = activeMessage();
    const { isSubAccount }                = cacheRole();
    const messageType                     = ref();
    const messageLevel                    = ref();

    onMounted(async () => {
      await load({ customQuery: { customer: 'customCustomer' } });

      isAuth.value = await isAuthenticated();

      if (isAuth.value && user !== null && user?.value?.role) {
        msg.value          = await loadMessage(user.value.role.customer_id);
        let messageData    = getMessage(msg.value);
        messageType.value  = messageData?.message_type;
        messageLevel.value = messageData?.message_level;
      }
    });

    const isMessageApproved = computed(() => {
      return messageType.value === "approved" || messageType.value === "approved_with_changes" || messageType.value === "requested" ? true : false;
    });

    const isSuccessMessage = computed(() => {
      return messageLevel.value === "success" ? true : false;
    });

    const isMessageReject = computed(() => {
      return messageType.value === "rejected" ? true : false;
    });

    // @ts-ignore
    return {
      isAuth,
      msg,
      getMessage,
      isSubAccount,
      isMessageApproved,
      isSuccessMessage,
      isMessageReject,
      messageType
    };
  },
})
