











import { SfLink } from '@storefront-ui/vue';

import { defineComponent, useContext, useRouter, } from '@nuxtjs/composition-api';

import HeaderLogo from '~/components/HeaderLogo.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { cacheRole } from '~/helpers/customer/role';
import { useCart, useUser } from '~/composables';

export default defineComponent({
  name: 'BannerConnectAs',
  components: {
    HeaderLogo,
    SvgImage,
    SfLink,
  },

  setup() {
    const { isCommercial } = cacheRole();
    const { user, logout } = useUser();
    const { clear } = useCart();


    const logoutConnectAs = async () => {
      await logout({});
      await clear({});
      window.location.reload();
    }

    return {
      isCommercial,
      user,
      logoutConnectAs
    }
  }
});
