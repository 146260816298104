import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseAquazurOrdersSearchParams, UseUserOrderErrors, UseUserOrderInterface, UseUserOrderSearchParams } from './useUserOrder';

/**
 * Allows fetching customer orders.
 *
 * See the {@link UseUserOrderInterface} for a list of methods and values available in this composable.
 */
export function useUserOrder(): UseUserOrderInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseUserOrderErrors>({
    search: null,
    getAquazurOrders: null,
    getAquazurQuotations: null
  });

  const search = async (params: UseUserOrderSearchParams) => {
    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] search user orders', { params });

      const { data } = await app.$vsf.$magento.api.customerOrders(params, params?.customQuery ?? null);

      Logger.debug('[Result]:', { data });

      results = data?.customer?.orders ?? null;
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useRelatedProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  const getAquazurOrders = async (params: UseAquazurOrdersSearchParams) => {
    let res = null;

    if (!params) {
      params = {};
    }
    const token = app.$cookies.get('shop_token_id', { parseJSON: false });
    params.tokenId = token ? token : '';

    try {
      loading.value = true;

      Logger.debug('[Magento] Search user aquazur orders', params);

      const { data } = await app.$vsf.$magento.api.getCustomerAquazurOrders(params, params?.customQuery ?? null);

      Logger.debug('[Result : User aquazur orders', { data });

      res = data.customer.aquazurOrders ?? null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useUserOrders/getAquazurOrders', err);
    } finally {
      loading.value = false;
    }

    return res;
  };

  const getAquazurQuotations = async (params: UseAquazurOrdersSearchParams) => {
    let res = null;

    if (!params) {
      params = {};
    }
    const token = app.$cookies.get('shop_token_id', { parseJSON: false });
    params.tokenId = token ? token : '';

    try {
      loading.value = true;

      Logger.debug('[Magento] Search user aquazur quotations', params);

      const { data } = await app.$vsf.$magento.api.getCustomerAquazurQuotations(params, params?.customQuery ?? null);

      Logger.debug('[Result : User aquazur quotations', { data });

      res = data.customer.aquazurQuotations ?? null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useUserOrders/getAquazurQuotations', err);
    } finally {
      loading.value = false;
    }

    return res;
  };

  const getQuotationPDF = async (params) => {
    let res = null;

    if (!params) {
      params = {};
    }
    const token = app.$cookies.get('shop_token_id', { parseJSON: false });
    params.tokenId = token ? token : '';

    try {
      loading.value = true;

      Logger.debug('[Magento] Transform quotation to PDF', params);

      const { data } = await app.$vsf.$magento.api.getUserQuotationPDF(params, params?.customQuery ?? null);

      Logger.debug('[Result : User quotation to PDF', { data });

      res = data?.getQuotationPDF ?? null
    } catch (err) {
      error.value.search = err;
      Logger.error('useUserOrders/getQuotationPDF', err);
    } finally {
      loading.value = false;
    }

    return res;

  }

  return {
    search,
    getAquazurOrders,
    getAquazurQuotations,
    getQuotationPDF,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useUserOrder;
export * from './useUserOrder';
