import { computed, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isFeatureTextOpen: false,
  isContactModalOpen: false,
  isSiretModalOpen: false,
  isCodClientModalOpen: false,
  isPasswordSidebarOpen: false,
  isStoreSidebarOpen: false,
  isShippingAddressSidebarOpen: false,
  isApprovalModalOpen: false,
  isPendingModalOpen: false,
  isOrderRejectModalOpen: false,
  isDeleteSubModalOpen: false,
  isAddToCartSidebarOpen: false,
  isAddToWishlistSidebarOpen: false,
  isCreateWishlistSidebarOpen: false,
  isRenameWishlistSidebarOpen: false,
  isChangeShopConfirmationSidebarOpen: false,
  isSortSidebarOpen: false,
  isQtyCalculatorSidebarOpen: false,
  isInfosCalculatePeintureModalOpen: false,
  isSortByCategoryOpen: false,
  isAccountInformationOpen: false,
  contentAccountInformation: { title: '', type: '', content: '' },
  displayAddressChantier: false,
  displayCreateAddressForm: false
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleLearnMoreModal = () => {
    state.isFeatureTextOpen = !state.isFeatureTextOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const toggleContactModal = () => {
    state.isContactModalOpen = !state.isContactModalOpen;
  };

  const toggleSiretModal = () => {
    state.isSiretModalOpen = !state.isSiretModalOpen;
  };

  const toggleCodClientModal = () => {
    state.isCodClientModalOpen = !state.isCodClientModalOpen;
  };

  const togglePasswordSidebar = () => {
    state.isPasswordSidebarOpen = !state.isPasswordSidebarOpen;
  };

  const toggleStoreSidebar = () => {
    state.isStoreSidebarOpen = !state.isStoreSidebarOpen;
  };

  const toggleShippingAddressSidebar = () => {
    state.isShippingAddressSidebarOpen = !state.isShippingAddressSidebarOpen;
  };

  const toggleApprovalModal = () => {
    state.isApprovalModalOpen = !state.isApprovalModalOpen;
  };

  const togglePendingModal = () => {
    state.isPendingModalOpen = !state.isPendingModalOpen;
  };

  const toggleOrderRejectModal = () => {
    state.isOrderRejectModalOpen = !state.isOrderRejectModalOpen;
  };

  const toggleDeleteSubModal = () => {
    state.isDeleteSubModalOpen = !state.isDeleteSubModalOpen;
  };

  const toggleAddToCartSidebar = () => {
    state.isAddToCartSidebarOpen = !state.isAddToCartSidebarOpen;
  };

  const toggleAddToWishlistSidebar = () => {
    state.isAddToWishlistSidebarOpen = !state.isAddToWishlistSidebarOpen;
  };

  const toggleCreateWishlistSidebar = () => {
    state.isCreateWishlistSidebarOpen = !state.isCreateWishlistSidebarOpen;
  };

  const toggleRenameWishlistSidebar = () => {
    state.isRenameWishlistSidebarOpen = !state.isRenameWishlistSidebarOpen;
  };

  const toggleChangeShopConfirmationSidebar = () => {
    state.isChangeShopConfirmationSidebarOpen = !state.isChangeShopConfirmationSidebarOpen;
  };

  const toggleSortSidebar = () => {
    state.isSortSidebarOpen = !state.isSortSidebarOpen;
  };

  const toggleQtyCalculatorSidebar = () => {
    state.isQtyCalculatorSidebarOpen = !state.isQtyCalculatorSidebarOpen;
  };

  const toggleInfosCalculatePeintureModal = () => {
    state.isInfosCalculatePeintureModalOpen = !state.isInfosCalculatePeintureModalOpen;
  };

  const toggleSortByCategory = () => {
    state.isSortByCategoryOpen = !state.isSortByCategoryOpen;
  };

  const toggleAccountInformationOpen = () => {
    state.isAccountInformationOpen = !state.isAccountInformationOpen;
  };

  const toggleDisplayAddressChantier = () => {
    state.displayAddressChantier = !state.displayAddressChantier;
  };

  const toggleDisplayCreateAddressForm = () => {
    state.displayCreateAddressForm = !state.displayCreateAddressForm;
  };

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isFeatureTextOpen: computed(() => state.isFeatureTextOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    isContactModalOpen: computed(() => state.isContactModalOpen),
    isSiretModalOpen: computed(() => state.isSiretModalOpen),
    isCodClientModalOpen: computed(() => state.isCodClientModalOpen),
    isPasswordSidebarOpen: computed(() => state.isPasswordSidebarOpen),
    isStoreSidebarOpen: computed(() => state.isStoreSidebarOpen),
    isShippingAddressSidebarOpen: computed(() => state.isShippingAddressSidebarOpen),
    isApprovalModalOpen: computed(() => state.isApprovalModalOpen),
    isPendingModalOpen: computed(() => state.isPendingModalOpen),
    isOrderRejectModalOpen: computed(() => state.isOrderRejectModalOpen),
    isDeleteSubModalOpen: computed(() => state.isDeleteSubModalOpen),
    isAddToCartSidebarOpen: computed(() => state.isAddToCartSidebarOpen),
    isAddToWishlistSidebarOpen: computed(() => state.isAddToWishlistSidebarOpen),
    isCreateWishlistSidebarOpen: computed(() => state.isCreateWishlistSidebarOpen),
    isRenameWishlistSidebarOpen: computed(() => state.isRenameWishlistSidebarOpen),
    isChangeShopConfirmationSidebarOpen: computed(() => state.isChangeShopConfirmationSidebarOpen),
    isSortSidebarOpen: computed(() => state.isSortSidebarOpen),
    isQtyCalculatorSidebarOpen: computed(() => state.isQtyCalculatorSidebarOpen),
    isInfosCalculatePeintureModalOpen: computed(() => state.isInfosCalculatePeintureModalOpen),
    isSortByCategoryOpen: computed(() => state.isSortByCategoryOpen),
    isAccountInformationOpen: computed(() => state.isAccountInformationOpen),
    contentAccountInformation: computed({
      get() {
        return state.contentAccountInformation;
      },
      set(data) {
        state.contentAccountInformation = data;
      }
    }),
    displayAddressChantier: computed(() => state.displayAddressChantier),
    displayCreateAddressForm: computed(() => state.displayCreateAddressForm),
    toggleMobileMenu,
    toggleLearnMoreModal,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleContactModal,
    toggleSiretModal,
    toggleCodClientModal,
    togglePasswordSidebar,
    toggleStoreSidebar,
    toggleShippingAddressSidebar,
    toggleApprovalModal,
    togglePendingModal,
    toggleOrderRejectModal,
    toggleDeleteSubModal,
    toggleAddToCartSidebar,
    toggleAddToWishlistSidebar,
    toggleCreateWishlistSidebar,
    toggleRenameWishlistSidebar,
    toggleChangeShopConfirmationSidebar,
    toggleSortSidebar,
    toggleQtyCalculatorSidebar,
    toggleInfosCalculatePeintureModal,
    toggleSortByCategory,
    toggleAccountInformationOpen,
    toggleDisplayAddressChantier,
    toggleDisplayCreateAddressForm
  };
}

export default useUiState;
export * from './useUiState';
