import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseProspectErrors, UseProspectInterface } from './useProspect';

export function useProspect(): UseProspectInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseProspectErrors>({
    getProspect: null
  });

  const search = async (infos) => {
    Logger.debug(`useProspect/getProspect`, infos);
    let prospect = null;

    try {
      loading.value = true;

      // @ts-ignore @TODO add establishment to ApiClientMethods
      prospect = await app.$vsf.$magento.api.prospect(infos);

      Logger.debug(`[Result] useProspect/getProspect : `, prospect);

      error.value.getProspect = null;
    } catch (err) {
      error.value.getProspect = err;
      Logger.error(`useProspect/getProspect`, err);
    } finally {
      loading.value = false;
    }

    return prospect;
  }

  return {
    search,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useProspect';
export default useProspect;
