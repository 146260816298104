<template>
    <nuxt-link v-if="$config.theme === 'zolpan'"
               :to="localePath('/')"
               class="sf-header__logo"
							 @click.native="menuMobileClose()"
    >
      <div class="cromo-logo desktop-only">
        <nuxt-img
          v-if="logoSrc"
          :alt="logoAlt"
          :height="logoHeight"
          :src="logoSrc"
          :title="logoAlt"
          :width="logoWidth"
        />
        <SvgImage
          v-else
          :label="$t('Vue Storefront Next')"
          height="37"
          icon="Logo_Cromo_Full"
          width="160"
        />
      </div>
      <div class="cromo-logo smartphone-only">
        <nuxt-img
          v-if="logoSrc"
          :alt="logoAlt"
          :height="logoHeight"
          :src="logoSrc"
          :title="logoAlt"
          :width="logoWidth"
        />
        <SvgImage v-else
                  :label="$t('Vue Storefront Next')"
                  class="smartphone-only"
                  height="18"
                  icon="Logo_Cromo"
                  width="106"
        />
      </div>
    </nuxt-link>
    <nuxt-link v-else
               :to="localePath('/')"
               class="sf-header__logo"
							 @click.native="menuMobileClose()"
    >
      <div class="cromo-logo desktop-only">
        <nuxt-img
          v-if="logoSrc"
          :alt="logoAlt"
          :height="logoHeight"
          :src="logoSrc"
          :title="logoAlt"
          :width="logoWidth"
        />
        <div v-else>
          <SvgImage
            v-if="!smallVersion"
            :label="$t('Vue Storefront Next')"
            height="36"
            icon="Logo_Cromo_Full"
            width="140"
          />
          <SvgImage
            v-else
            :label="$t('Vue Storefront Next')"
            height="30"
            icon="Logo_Cromo_Full"
            width="118"
          />
        </div>

      </div>
      <div class="cromo-logo smartphone-only">
        <nuxt-img
          v-if="logoSrc"
          :alt="logoAlt"
          :height="logoHeight"
          :src="logoSrc"
          :title="logoAlt"
          :width="logoWidth"
        />
        <div v-else>
          <SvgImage v-if="alternativeVersion"
                    :label="$t('Vue Storefront Next')"
                    class="smartphone-only"
                    height="22"
                    icon="Logo_Cromo"
                    width="119"
          />
          <SvgImage v-else
                    :label="$t('Vue Storefront Next')"
                    class="smartphone-only"
                    height="27"
                    icon="Logo_Cromo_Full"
                    width="106"
          />
        </div>
      </div>
    </nuxt-link>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import {useConfig, useUiState} from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'HeaderLogo',
  components: { SvgImage },
  props: {
    smallVersion: {
      type: Boolean,
      default: false
    },
    alternativeVersion: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { config } = useConfig();
		const { isMobileMenuOpen, toggleMobileMenu } = useUiState();

		const logoSrc = computed(() => {
      const baseMediaUrl = config.value.base_media_url;
      const logo         = config.value.header_logo_src;

      return baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : '';
    });

    const logoWidth = computed(
      () => config.value.logo_width || '35',
    );

    const logoHeight = computed(
      () => config.value.logo_height || '34',
    );

    const logoAlt = computed(
      () => config.value.logo_alt || '',
    );

		const menuMobileClose = () => {
			isMobileMenuOpen.value && toggleMobileMenu();
			document.body.classList.remove('no-scroll');
		};

    return {
      logoAlt,
      logoHeight,
      logoSrc,
      logoWidth,
			menuMobileClose
    };
  },
});
</script>

<style lang="scss" scoped>
.sf-header__logo {
  @include for-desktop {
    align-items: center;
    display: inline-flex;
    min-height: 80px;
  }
}
</style>
