import { defineStore } from 'pinia';

interface ProductState {
  product: {
    sku: string,
    color: string,
    customColorExa: string,
    customerColorLabel: string,
    conditionning: string,
  },
  productModal: {
    id: string
    articleCode: string
    color: string
    conditioning: string
    configuration: string
    image: Object
    name: string
    quantity: Number
    taxe: string
    total: [string, number, boolean]
    type: string
  }
}

export const useProductStore = defineStore('product', {
  state: (): ProductState => ({
    product: {
      sku: '',
      color: '',
      customColorExa: '',
      customerColorLabel: '',
      conditionning: '',
    },
    productModal: {
      id: '',
      articleCode: '',
      color: '',
      conditioning: '',
      configuration: '',
      image: {  },
      name: '',
      quantity: 0,
      taxe: '',
      total: ['', 0, false],
      type: '',
    }
  }),
  getters: {
    getProduct: (state) => {
      const storedProduct = localStorage.getItem('productStored');
      return storedProduct ? JSON.parse(storedProduct) : state.product;
    }
  },
  actions: {
    setProduct(product) {
      this.product = {
        ...product
      }

      localStorage.setItem('productStored', JSON.stringify(this.product))
    },
    setProductModal(product) {
      this.productModal = {
        ...product
      }
    }
  },
});
