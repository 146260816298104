import defaultI18N from "../../default/lang/fr"

export default {
  ...defaultI18N,
  "Customer Cromology": "de client Zolpan",
  "Does your society already have a client account ?": "Votre société a-t-elle déjà un compte client Zolpan ?",
  "No, i don't have an account": "Non, je n'ai pas de compte client Zolpan mais je souhaite ouvrir un accès web professionnel",
  "Yes, I am already client": "Oui, je suis déjà client Zolpan et je souhaite avoir un accès web.",
  "home_meta_title": "Peinture professionnelle intérieur, façade, bois, fer, sol et ITE",
  "home_meta_description": "100% français. Choix infini de couleurs. Enduits murs et sols, outils, ponceuses, papier peint, toile de verre, colles. Click&Collect, financement, livraison, collecte des déchets.",
  "site_name": "Zolpan",
  "contact_meta_description": "Contact - Conseils et expertise Zolpan pour les particuliers et les professionnels - Fabricant et distributeur de peintures, revêtements sol et mur et solutions d'isolation ITE.",
  "category_meta_description": "100% français. Choix infini de couleurs. Enduits murs et sols, outils, ponceuses, papier peint, toile de verre, colles. Click&Collect, financement, livraison, collecte des déchets.",
};
