import type { Ref } from '@nuxtjs/composition-api';
import { computed, readonly, ref, useContext, useRouter, } from '@nuxtjs/composition-api';
import mask from '~/composables/utils/mask';
import { Logger } from '~/helpers/logger';
import { useCurrentShop } from '~/modules/customer/composables/useCurrentShop';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCart } from '~/modules/checkout/composables/useCart';
import { generateUserData } from '~/modules/customer/helpers/generateUserData';
import { useUiNotification } from '~/composables/useUiNotification';
import type { Customer } from '~/modules/GraphQL/types';
import type {
  UseUserChangePasswordParams,
  UseUserErrors,
  UseUserInterface,
  UseUserLoadParams,
  UseUserLoginParams,
  UseUserLogoutParams,
  UseUserRegisterParams,
  UseUserUpdateUserParams,
} from './useUser';
import { customerShopCode } from '~/modules/customer/helpers/customerShopCode';

/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export function useUser(): UseUserInterface {
  const customerStore = useCustomerStore();
  // @ts-ignore
  const { app, $recaptcha } = useContext();
  const { setCart } = useCart();
  const { send: sendNotification } = useUiNotification();
  const loading: Ref<boolean> = ref(false);
  const isLoadAuth = ref(false);
  const errorsFactory = (): UseUserErrors => ({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null,
    tryToAutoConnect: null
  });
  const error: Ref = ref(errorsFactory());
  const { get: getCurrentShop } = useCurrentShop();
  const { setCustomerShopCode, unsetCustomerShopCode } = customerShopCode();

  const isAuthenticated = async (): Promise<boolean> => {

    if (!isLoadAuth.value) {
      isLoadAuth.value = true;
      if (customerStore) {
        if (!app.$vsf.$magento.config.state.getCustomerToken()) {
          await tryToAutoConnect();
        }
      }
      isLoadAuth.value = false;
    }

    // On utilise le getCustomerToken pour vérifier que le client est connecté, car isLoggedIn n'est pas fiable
    const isAuth = app.$vsf.$magento.config.state.getCustomerToken() ? true : false;
    customerStore.setIsLoggedIn(isAuth);

    return isAuth;
  }

  const setUser = (customer: Customer | null) => {
    customerStore.setUser(customer);
    Logger.debug('useUserFactory.setUser', customer);

    if (customer !== null) {
      app.$vsf.$cromology.config.state.setCustomerRole(customer.role);
    } else {
      app.$vsf.$cromology.config.state.removeCustomerRole();
    }
  };

  const resetErrorValue = () => {
    error.value = errorsFactory();
  };

  const updateCustomerEmail = async (credentials: { email: string, password: string }): Promise<void> => {
    const { errors } = await app.context.$vsf.$magento.api.updateCustomerEmail(credentials);

    if (errors) {
      throw errors.map((e) => e.message).join(',');
    }
  };

  // eslint-disable-next-line consistent-return
  const updateUser = async ({ user: providedUser, customQuery }: UseUserUpdateUserParams) => {
    Logger.debug('[Magento] Update user information', { providedUser, customQuery });
    resetErrorValue();

    try {
      loading.value = true;
      const { email: oldEmail } = customerStore.user;
      const { email, password, ...updateData } = providedUser;

      const userData = generateUserData(updateData);

      if (email && email !== oldEmail) {
        await updateCustomerEmail({
          email,
          password,
        });
      }

      const { data, errors } = await app.context.$vsf.$magento.api.updateCustomer(userData, customQuery);
      Logger.debug('[Result]:', { data });

      if (errors) {
        const allErrorMessages = errors.map((e) => e.message).join(',');
        Logger.error(allErrorMessages);
        error.value.updateUser = allErrorMessages;
      }

      setUser(data?.updateCustomerV2?.customer ?? null);
      error.value.updateUser = null;
    } catch (err) {
      error.value.updateUser = err;
      Logger.error('useUser/updateUser', err);
    } finally {
      loading.value = false;
    }
  };

  const logout = async ({ customQuery = {} }: UseUserLogoutParams = {}) => {
    Logger.debug('[Magento] useUserFactory.logout');
    resetErrorValue();

    try {
      const apiState = app.context.$vsf.$magento.config.state;

      await app.context.$vsf.$magento.api.revokeCustomerToken(customQuery);

      unsetCustomerShopCode();

      app.$cookies.set('persistent_token', '', {
        path: '/',
        maxAge: 60 * 60 * 24 * 365
      });

      apiState.removeCartId();
      setCart(null);

      apiState.removeCustomerToken();
      customerStore.setIsLoggedIn(false);
      setUser(null);

      error.value.logout = null;
    } catch (err) {
      error.value.logout = err;
      Logger.error('useUser/logout', err);
    }
  };

  const load = async ({ customQuery = { customer: 'customCustomer' } }: UseUserLoadParams = {}) => {
    Logger.debug('[Magento] useUser.load');
    resetErrorValue();

    try {
      loading.value = true;

      const apiState = app.context.$vsf.$magento.config.state;

      apiState.getCustomerToken();

      if (!apiState.getCustomerToken()) {
        return null;
      }

      const token = app.$cookies.get('shop_token_id', { parseJSON: false });
      const tokenId = token ? token : '';

      try {
        const { data } = await app.context.$vsf.$magento.api.customCustomer(tokenId);

        Logger.debug('[Result]:', { data });

        setUser(data?.customer ?? null);
      } catch {
        // eslint-disable-next-line no-void
        // @ts-ignore
        await logout();
      }
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useUser/load', err);
    } finally {
      loading.value = false;
    }

    return customerStore.user;
  };

  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const login = async ({
                         user: providedUser,
                         customQuery = { generateCustomerToken: 'customGenerateCustomerToken' }
                       }: UseUserLoginParams): Promise<void> => {
    Logger.debug('[Magento] useUser.login', providedUser);
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      const { data, errors } = await app.$vsf.$magento.api.generateCustomerToken(
        {
          email: providedUser.email,
          password: providedUser.password,
          recaptchaToken: providedUser.recaptchaToken,
        },
        customQuery || {},
      );

      Logger.debug('[Result]:', { data });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) => sendNotification({
          icon: 'error',
          id: Symbol(`registration_error-${i}`),
          message: registerError.message,
          persist: true,
          title: 'Registration error',
          type: 'danger',
        }));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors);
      }

      apiState.setCustomerToken(data.generateCustomerToken.token);

      if (data.generateCustomerToken.persistentLoginToken) {
        app.$cookies.set('persistent_token', data.generateCustomerToken.persistentLoginToken, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365
        })
      }

      const customerData = await app.context.$vsf.$magento.api.customer({ customQuery: { customer: 'customCustomer' } });
      setUser(customerData?.customer ?? null);
      const shop = await getCurrentShop();
      shop && setCustomerShopCode(shop.current_shop);

      const token = app.$cookies.get('shop_token_id', { parseJSON: false });
      const tokenId = token ? token : '';

      await setCartWhenLoggedIn(apiState, tokenId);

      error.value.login = null;
      customerStore.setIsLoggedIn(true);
    } catch (err) {
      error.value.login = err;
      Logger.error('useUser/login', err);
    } finally {
      loading.value = false;
    }
  };

  const tryToAutoConnect = async (tokenParam = '', force = false): Promise<string> => {
    loading.value = true;
    let retour = 'no_token';

    try {
      if (customerStore.getHasTriedToAutoconnect && !force) {
        loading.value = false;
        return;
      }

      if (app.$vsf.$magento.config.state.getCustomerToken() && !force) {
        customerStore.setIsLoggedIn(true);
        loading.value = false;
        return;
      }

      customerStore.setIsLoggedIn(false);
      const apiState = app.context.$vsf.$magento.config.state;
      if (apiState.getCustomerToken() && !force) {
        return 'already-connected';
      }

      //Get token
      const token = app.$cookies.get('persistent_token', { parseJSON: false });
      const persistentToken = token ? token : tokenParam;

      if (!persistentToken) {
        loading.value = false;
        return 'no_token';
      }

      const { data, errors } = await app.$vsf.$magento.api.requestLoginByToken(persistentToken);
      Logger.debug('[Result requestLoginByToken]:', { data });

      if (errors) {
        customerStore.setHasTriedToAutoconnect(true);
        loading.value = false;
        return 'no_token';
      } else if (data?.requestLoginByToken?.persistentLoginToken === persistentToken) {
        apiState.setCustomerToken(data?.requestLoginByToken?.token);

        const customerData = await app.context.$vsf.$magento.api.customer({ customQuery: { customer: 'customCustomer' } });
        setUser(customerData?.customer ?? null);
        const shop = await getCurrentShop();
        setCustomerShopCode(shop.current_shop);

        const token = app.$cookies.get('shop_token_id', { parseJSON: false });
        const tokenId = token ? token : '';

        customerStore.setIsLoggedIn(true);
        await setCartWhenLoggedIn(apiState, tokenId);

        error.value.login = null;
        loading.value = false;
        retour = tokenId;
      }
    } catch (err) {
      error.value.login = err;
      Logger.error('useUser/login', err);
    } finally {
      loading.value = false;
    }

    return retour;
  };

  // eslint-disable-next-line consistent-return
  const register = async ({ user: providedUser, customQuery }: UseUserRegisterParams): Promise<void> => {
    Logger.debug('[Magento] useUser.register', providedUser);
    resetErrorValue();

    try {
      loading.value = true;

      const {
        email,
        password,
        recaptchaToken,
        ...baseData
      } = generateUserData(providedUser);

      const { data, errors } = await app.$vsf.$magento.api.createCustomer(
        {
          email,
          password,
          recaptchaToken,
          ...baseData,
        },
        customQuery || {},
      );

      Logger.debug('[Result]:', { data });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) => sendNotification({
          icon: 'error',
          id: Symbol(`registration_error-${i}`),
          message: registerError.message,
          persist: true,
          title: 'Registration error',
          type: 'danger',
        }));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors);
      }
      error.value.register = null;
      let loginRecaptchaToken = '';
      if ($recaptcha && recaptchaToken) {
        loginRecaptchaToken = await $recaptcha.getResponse();
      }

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { customer: { customer_create_account_confirm } } = app.context.$vsf.$magento.config;

      if (customer_create_account_confirm) {
        return await new Promise((resolve) => {
          sendNotification({
            id: Symbol('registration_confirmation'),
            message: app.i18n.t('You must confirm your account. Please check your email for the confirmation link.') as string,
            persist: true,
            title: 'Registration confirmation',
            type: 'success',
            icon: 'check',
          });

          resolve();
        });
      }
      await login({ user: { email, password, recaptchaToken: loginRecaptchaToken }, customQuery: {} });
    } catch (err) {
      error.value.register = err;
      Logger.error('useUser/register', err);
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line consistent-return
  const changePassword = async (params: UseUserChangePasswordParams) => {
    Logger.debug('[Magento] useUser.changePassword', { currentPassword: mask(params.current), newPassword: mask(params.new) });
    resetErrorValue();

    try {
      loading.value = true;

      const { data, errors } = await app.context.$vsf.$magento.api.changeCustomerPassword({
        currentUser: customerStore.user,
        currentPassword: params.current,
        newPassword: params.new,
      }, params.customQuery);

      let joinedErrors = null;

      if (errors) {
        joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
      }

      Logger.debug('[Result] ', { data });

      customerStore.user = data?.changeCustomerPassword;
      customerStore.passwordChanged = true;
      error.value.changePassword = joinedErrors;
    } catch (err) {
      error.value.changePassword = err;
      Logger.error('useUser/changePassword', err);
    } finally {
      loading.value = false;
    }
  };

  const setCartWhenLoggedIn = async (apiState: object, tokenId: string) => {
    const currentCartId = apiState.getCartId();
    const cart = await app.context.$vsf.$magento.api.customCustomerCart(tokenId);
    const newCartId = cart.data.customerCart.id;

    try {
      if (newCartId && currentCartId && currentCartId !== newCartId) {
        const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts(
          {
            sourceCartId: currentCartId,
            destinationCartId: newCartId,
          },
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCart(dataMergeCart.mergeCarts);

        apiState.setCartId(dataMergeCart.mergeCarts.id);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCart(cart.data.customerCart);
      }
    } catch {
      // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
      // It can be removed when Magento 2.4.5 will be release
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setCart(cart.data.customerCart);
    }
  }

  return {
    setUser,
    updateUser,
    register,
    login,
    tryToAutoConnect,
    logout,
    changePassword,
    load,
    isAuthenticated,
    loading: readonly(loading),
    error: readonly(error),
    user: computed(() => customerStore.user),
  };
}

export default useUser;
export * from './useUser';
