import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';

export function useEstablishment() {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref({
    search: null,
    load: null,
  });

  const search = async (params) => {
    let establishment = null;
    let errorMsg = null;

    try {
      loading.value = true;

      Logger.debug('[Magento]: Search establishment information based on', { params });

      // @ts-ignore @TODO add establishment to ApiClientMethods
      const res = await app.$vsf.$magento.api.establishment(params);

      Logger.debug('[Result]:', res);

      establishment = res?.data?.establishment ?? null;

      if (establishment !== null) {
        establishment = {
          company_name: res?.data?.establishment.name,
          address: [res?.data?.establishment.streetNumber, res?.data?.establishment.streetType, res?.data?.establishment.streetName].join(' '),
          post_code: res?.data?.establishment.postalCode,
          city_name: res?.data?.establishment.cityName,
          city_code: res?.data?.establishment.cityCode,
          is_already_used: res?.data?.establishment.isAlreadyUsed
        }

        error.value.search = null
      } else {
        errorMsg = res.errors[0].message;
      }
    } catch (err) {
      error.value.search = err;
      Logger.error('useEstablishment/search', err);
    } finally {
      loading.value = false;
    }

    return { establishment, errorMsg };
  };

  return {
    search,
  };
}

export default useEstablishment;
