<template>
  <div
    v-if="promo"
    class="mega-menu-navbar mega-menu-navbar-promo"
  >
    <div class="mega-menu-item mega-menu-mobile-item">
      <slot name="desktop-navigation-item">
        <SfLink
          :link="localePath('/promo')"
          class="mega-menu-item-link mega-menu-item-link-main mega-menu-item-link-promo desktop-only"
        >
          <span class="label">
            {{ $t('Special Offers') }}
          </span>
        </SfLink>
        <SfLink
          :link="localePath('/promo')"
          class="mega-menu-mobile-item-link mega-menu-item-link-promo smartphone-only"
          @click.native="menuMobileClose()"
        >
          <span class="label">
            {{ $t('Special Offers') }}
          </span>
          <span class="sf-icon">
            <SvgImage
              height="7"
              icon="Icon_chevron--orange"
              width="14"
            />
          </span>
        </SfLink>
      </slot>
    </div>
  </div>
</template>
<script>
import { SfLink } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useUiState } from '~/composables';

export default {
  name: 'HeaderNavigationItemsPromo',
  components: {
    SfLink,
    SvgImage,
  },
  props: {
    promo: {
      type: [String, Object],
      default: '',
    },
  },
  setup() {
    const { toggleMobileMenu } = useUiState();
    const menuMobileClose      = () => {
      toggleMobileMenu();
      document.body.classList.remove('no-scroll');
    };
    return {
      menuMobileClose,
    };
  }
};
</script>
