

















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import { ProductFlapType } from '~/custom-api/api/productAttributeData/types';
import { cachePrice } from '~/helpers/customer/role';

export default defineComponent({
  name: 'PictimeFlap',
  props: {
    oldPrice: {
      type: [String, Number, Boolean],
    },
    packagingOldPrice: {
      type: String,
      default: ''
    },
    discountPercent: {
      type: [String, Number],
      default: '20'
    },
    flap: {
      type: String as PropType<ProductFlapType>,
      default: ''
    },
    isFromPrice: {
      type: Boolean,
      default: true
    },
    taxe: {
      type: String,
      default: ''
    },
    unitMeasure: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const { config } = useConfig();

    const reducLabel = computed((): string => {
      switch (props.flap) {
        case ProductFlapType.Promo:
          return 'PROMOTION';
        case ProductFlapType.Remise:
          return 'VOTRE REMISE';
        case ProductFlapType.Nouveau:
          return 'NOUVEAU';
        case ProductFlapType.Destock:
          return config.value.label_destockage;
      }

      return '';
    });

    const { canViewPrice } = cachePrice();

    return {
      reducLabel,
      canViewPrice
    }
  },

  computed: {
    reducClass(): string {
      switch (this.flap) {
        case ProductFlapType.Promo:
          return 'promo';
        case ProductFlapType.Remise:
          return 'price';
        case ProductFlapType.Nouveau:
          return 'new';
        case ProductFlapType.Destock:
          return 'destock';
      }

      return '';
    }
  }
})
