const calculStock = (stock, qteProduit, allowResupply = true) => {
  let stocks = {
    store: { isOk: false, label: 'Indisponible magasin' },
    delivery: { isOk: false, label: 'Livraison indisponible' }
  }

  calculStockMag(stocks, stock, qteProduit, allowResupply);
  calculStockLivraison(stocks, stock, qteProduit);

  return stocks;
}

const calculStockMag = (stocks, stock, qteProduit, allowResupply) => {
  if (stock.available >= qteProduit) {
    stocks.store = { isOk: true, label: 'Click & Collect 3H' };
  }

  if (stock.available < qteProduit) {
    if (stock.statutArticleMag == 'P' || stock.statutArticleMag == 'S' || stock.statutArticleMag == 'V') {
      if (stock.resupplyTime > 30 && allowResupply) {
        stocks.store = { isOk: true, label: 'Contactez votre magasin pour retrait' }
      } else if (allowResupply) {
        stocks.store = { isOk: true, label: 'Retrait en magasin sous ' + stock.resupplyTime + ' jours' }
      }
    }

    if (stock.approDirecteFournisseur) {
      stocks.store = { isOk: false, label: 'Indisponible magasin' }
    }

    if (stock.statutArticleMag == 'U' || stock.statutArticleMag == 'O') {
      stocks.store = { isOk: false, label: 'Indisponible magasin' }
    }
  }
}

const calculStockLivraison = (stocks, stock, qteProduit) => {
  if (stock.livraisonPro && !stock.approDirecteFournisseur && stock.statutArticleMag !== 'O') {
    stocks.delivery = { isOk: true, label: 'Livraison disponible' };

    if(stock.statutArticleMag == 'U') {
      if(stock.available >= qteProduit) {
        stocks.delivery = { isOk: true, label: 'Livraison disponible' };
      } else {
        stocks.delivery = { isOk: false, label: 'Livraison indisponible' }
      }
    }
  }
}

export default calculStock;
