<template>
  <div id="CromologyStoreLocator" class="CromologyStoreLocator">
    <div :class="{ 'CromologyStoreLocator-ContainerOverlay': open }" @click="close"></div>
    <div :class="{ 'CromologyStoreLocator-Container--open': open }" class="CromologyStoreLocator-Container">
      <div class="CromologyStoreLocator-Header">
        <div>Choisir un magasin</div>
        <div class="CromologyStoreLocator-HeaderButton" @click="close">
          <svg fill="none" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21 20.775 1 1M21 1 1 20.775"
              stroke="#111"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
            />
          </svg>
        </div>
      </div>
      <div class="CromologyStoreLocator-HeaderSubtitle">{{ subtitleText }}</div>
      <div class="CromologyStoreLocator-Search">
        <div
          :class="{ 'CromologyStoreLocator-SearchContainerBordered': predictions.length }"
          class="CromologyStoreLocator-SearchContainer"
        >
          <div class="CromologyStoreLocator-SearchIcon">
            <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
              <path
                :stroke="[predictions.length ? '#13595f' : '#000']"
                d="M7.588 14.177A6.588 6.588 0 1 0 7.588 1a6.588 6.588 0 0 0 0 13.177ZM12.295 12.294 17 17"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-width="1.5"
              />
            </svg>
          </div>
          <input
            v-model="search"
            class="CromologyStoreLocator-SearchInput"
            placeholder="Adresse de recherche"
            @focus="fetchPredictions"
          />
          <ul v-if="predictions.length" class="CromologyStoreLocator-SearchDropdown">
            <li
              v-for="result in predictions"
              :key="result.placeId"
              tabindex="0"
              @click="fetchSelectedStore(result.placeId, result.description)"
            >
              {{ result.description }}
            </li>
          </ul>
        </div>

        <span class="CromologyStoreLocator-SearchSeparator">ou</span>
        <button class="CromologyStoreLocator-OutlinedButtonIcon sf-button sf-button__secondary" @click="locateMe">
          <svg fill="none" height="21" viewBox="0 0 24 25" width="20" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.034 13.153a.75.75 0 0 0-.693-.693l-9.654-.705C.595 11.619.403 10.109 1.405 9.7h.002l20.361-8.368h.002c.896-.362 1.749.543 1.392 1.375h0l-.004.01-8.363 20.37c-.463 1.07-1.952.757-2.055-.267l-.706-9.667Z"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
            />
          </svg>
          <span>Géolocalisez-moi</span>
        </button>
      </div>
      <div v-for="(store, index) in stores" :key="store.storeCode" class="CromologyStoreLocator-Stores">
        <div class="CromologyStoreLocator-Store" @click="selectStore(index)">
          <div class="CromologyStoreLocator-StoreBox">
            <div class="CromologyStoreLocator-StoreBoxTextButton">
              <input :checked="store.checked" name="storeSelect" type="radio"/>
              <div class="CromologyStoreLocator-StoreInfos">
                <span class="CromologyStoreLocator-StoreName">{{ store.titleLabel }}</span>
                <span>{{ store.address.addressLines[0] }}</span>
                <span v-if="store.address.addressLines[1]">{{ store.address.addressLines[1] }}</span>
                <span>{{ store.address.postalCode + ' ' + store.address.locality }}</span>
              </div>
            </div>
            <div class="CromologyStoreLocator-StoreActions">
              <button class="CromologyStoreLocator-Button sf-button sf-button__primary" @click="chooseStore(index, $event)">
                Choisir ce magasin
              </button>
              <button class="CromologyStoreLocator-UnderlinedButton" @click="toggleDetails(index, $event)">
                Voir le détail
              </button>
            </div>
          </div>
          <div
            :class="{ 'CromologyStoreLocator-StoreBoxDetails--open': store.detailsOpened }"
            class="CromologyStoreLocator-StoreBoxDetails"
          >
            <div class="CromologyStoreLocator-StoreBoxDetailsInfos">
              <div class="CromologyStoreLocator-StoreSchedule">
                <p v-for="day in days" :key="`${store.storeCode}-${day.jour}`">
                  <strong> {{ day.jour }}</strong>
                  <span v-if="store.schedules[day.day]">{{ store.schedules[day.day] }}</span>
                  <span v-else>Fermé</span>
                </p>
              </div>
              <div class="CromologyStoreLocator-StoreContact">
                <div
                  v-if="formatSpecialHours(store.specialHours).length > 0"
                  class="CromologyStoreLocator-SpecialHours"
                >
                  <span><strong>Horaires exceptionnels</strong></span>
                  <p v-for="date in formatSpecialHours(store.specialHours)" :key="`${store.storeCode}-${date.id}`">
                    <span class="CromologyStoreLocator-SpecialHoursDate">{{ date.date }}</span>
                    <span v-if="date.time && date.time !== 'x'">{{ date.time }}</span
                    ><span v-else>Fermé</span>
                  </p>
                </div>
                <div>Téléphone : {{ store.primaryPhone.replace(/\s/g, '').replace(/.{2}/g, '$& ') }}</div>
                <div>Fax : {{ store.fax.replace(/\s/g, '').replace(/.{2}/g, '$& ') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import subDays from 'date-fns/subDays'
import { useContext } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';

export default {
  name: 'CromologyStoreLocator',
  props: {
    company: {
      type: String,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    },
    /*provider: {
      required: true
    },*/
    subtitleText: {
      type: String,
      default: "La sélection d'un autre magasin peut influencer la disponibilité des produits sélectionnés."
    },
    initSearchTerm: {
      type: String,
      required: false
    },
    distance: {
      type: Number,
      required: false
    },
    storeCode: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      debouncedSearch: '',
      favStore: null,
      predictions: {},
      search: this.initSearchTerm || '',
      stores: null,
      isPredictionSelected: false,
      days: [
        { jour: 'Lundi', day: 'monday' },
        { jour: 'Mardi', day: 'tuesday' },
        { jour: 'Mercredi', day: 'wednesday' },
        { jour: 'Jeudi', day: 'thursday' },
        { jour: 'Vendredi', day: 'friday' },
        { jour: 'Samedi', day: 'saturday' }
      ]
    }
  },
  async created() {
    // Handle case when initSearchTerm is given by props
    if (this.initSearchTerm) {
      const predictions = await this.fetchPlaces(this.initSearchTerm)

      if (predictions.length) {
        await this.fetchSelectedStore(predictions[0].placeId, predictions[0].description)
      }
    } else {
      await this.fetchNearestStores()
      this.favStore = JSON.parse(localStorage.getItem('favoriteStore'))
    }
  },
  watch: {
    favStore() {
      if (this.favStore && this.favStore.value && this.stores) {
        let index = this.stores.findIndex((store) => {
          return store.storeCode === this.favStore.value
        })
        if (index >= 0) {
          this.selectStore(index)
        }
      }
    },
    search: debounce(async function (newVal) {
      this.debouncedSearch = newVal // valeur champ
      if (!this.isPredictionSelected) {
        this.predictions = await this.fetchPlaces(newVal) //valeurs à afficher dans le dropdown
      }
      this.isPredictionSelected = false
    }, 300)
  },
  setup(props) {
    const { app }   = useContext();
    const cromology = app.context.$vsf.$cromology;

    return {
      cromology,
    }
  },
  methods: {
    async fetchPredictions() {
      if (this.search) {
        this.predictions = await this.fetchPlaces(this.search) //valeurs à afficher dans le dropdown
      }
    },
    chooseStore(index, event) {
      event.stopPropagation()

      let expiry = new Date()
      expiry.setMonth(expiry.getMonth() + 6)

      localStorage.setItem(
        'favoriteStore',
        JSON.stringify({
          value: this.stores[index].storeCode,
          expiry: expiry.getTime()
        })
      )

      this.favStore = JSON.parse(localStorage.getItem('favoriteStore'))

      // PATCH PICTIME ***************************************************
      this.$emit('Cromology::StoreLocator::StoreSelected', this.stores[index]);
      this.$emit('Cromology::StoreLocator::Close');
      // PATCH PICTIME END ***********************************************
    },
    async close() {
      this.$emit('Cromology::StoreLocator::Close')
    },
    async fetchPlaceDetails(placeId) {
      const response = await this.cromology.api.placeDetails({ placeId });

      return response.data.placeDetails
    },
    async fetchPlaces(location) {
      const input    = location;
      const response = await this.cromology.api.placeAutocomplete({ input });

      return response.data.placeAutocomplete
    },
    async fetchNearestStores(latlng) {
      let variables = {}

      if (latlng && typeof latlng === 'object') {
        variables.latlng = latlng
      } else {
        variables.size = 1000
      }

      if (this.distance) {
        variables.distance = this.distance + 'km';
        if (latlng) {
          variables.size = 1000;
        }
      }

      try {
        const { latlng, size, distance } = variables;
        const response                   = await this.cromology.api.nearestStores({ latlng, size, distance });

        let preselectedStoreIndex = null;
        // PATCH PICTIME ***************************************************
        let preselectedStore      = false;
        // PATCH PICTIME END ***********************************************

        if (response && response.data && response.data.nearestStores) {
          this.stores = response.data.nearestStores;
          this.stores.forEach((store, index) => {
            store.detailsOpened = false;
            store.checked       = false;
            if (this.distance && latlng && this.storeCode && this.storeCode === store.storeCode) {
              store.checked         = true;
              store.detailsOpened   = true;
              preselectedStoreIndex = index;
              preselectedStore      = true;
              // PATCH PICTIME ***************************************************
              this.$emit('store_found_within_the_boundaries', store.storeCode);
              // PATCH PICTIME END ***********************************************
            }
          })
        }

        // PATCH PICTIME ***************************************************
        if (!preselectedStore) {
          // PATCH PICTIME END ***********************************************
          this.favStore = JSON.parse(localStorage.getItem('favoriteStore'))
          // PATCH PICTIME ***************************************************
          if (this.distance && this.storeCode) {
            this.$emit('store_not_found_within_the_boundaries');
          }
          // PATCH PICTIME END ***********************************************
        } else {
          const preselectedStore = this.stores[preselectedStoreIndex]
          this.stores.splice(preselectedStoreIndex, 1)
          this.stores = [preselectedStore, ...this.stores]
        }
      } catch (error) {
        console.log(error)
      }
    },
    formatSpecialHours(specialHours) {
      let infos = specialHours.split(',')
      let today = new Date()
      let previewLimit
      let dates = []
      let id    = 1;

      infos.forEach((date) => {
        const baseDate = date.trim().split(' ')[0].replace(/:/g, '')
        const day      = new Date(baseDate)
        previewLimit   = subDays(new Date(day), 15)

        const baseDateSplit = baseDate.split('-')

        let [dateYear, dateMonth, dateDay] = baseDateSplit

        if (isAfter(today, previewLimit) && isBefore(today, day)) {
          dates.push({
            date: `${dateDay.padStart(2, '0')}-${dateMonth.padStart(2, '0')}-${dateYear}`,
            time: date.trim().split(' ')[1],
            id: id
          })
          id++;
        }
      })

      return dates
    },
    async fetchSelectedStore(placeId, placeName) {
      this.isPredictionSelected = true
      const placeDetails        = await this.fetchPlaceDetails(placeId)
      this.search               = placeName
      await this.fetchNearestStores(placeDetails.geolocation)
      this.predictions = []
    },
    async locateMe() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          await this.fetchNearestStores({
            lat: position.coords.latitude,
            lon: position.coords.longitude
          })
        })
      } else {
        console.log('Geolocalisation error')
      }
    },
    async selectStore(index) {
      this.stores.forEach((store) => {
        if (store.checked) {
          store.checked       = false
          store.detailsOpened = false
        }
      })
      this.stores[index].detailsOpened = true
      this.stores[index].checked       = true
      this.$set(this.stores, index, this.stores[index])
    },
    toggleDetails(index, event) {
      event.stopPropagation()
      this.stores[index].detailsOpened = !this.stores[index].detailsOpened
      this.$set(this.stores, index, this.stores[index])
    }
  }
}
</script>

<style lang="scss" scoped>
// StoreLocator CSS component

//Rectangular button green
.Button--action {
  padding: 15px 35px;
  border: 1px solid;
  color: #ffffff;
  transition: all ease 0.3s;
  background-color: #13595f;
  cursor: pointer;

  &:hover {
    color: #13595f;
    border: 1px solid #13595f;
    background-color: #ffffff;
  }
}

//inverted button green
.Button--tollensgreenrevert {
  @extend .Button--action;
  color: #13595f;
  border-color: #13595f;
  background-color: #ffffff;

  &:hover {
    color: #ffffff;
    border-color: #ffffff;
    background-color: #13595f;
  }
}

// Buttons override
.CromologyStoreLocator-UnderlinedButton {
  margin-top: 12px;
  cursor: pointer;
  font-size: 14px;
  background-color: transparent;
  border: none;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.CromologyStoreLocator-Button {
  width: 280px;
  font-size: 14px;
  line-height: 16px;
}

.CromologyStoreLocator-OutlinedButtonIcon {
  display: flex;
  align-items: center;
  padding: 11px 15px;
  font-size: 14px;
  white-space: nowrap;

  svg {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .CromologyStoreLocator-OutlinedButtonIcon {
    width: 100%;
    justify-content: center;
    padding: 13px 15px;
  }
}

// New classes
.CromologyStoreLocator-ContainerOverlay {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #111111;
  opacity: 0.3;
  transition: 0.6s;
}

.CromologyStoreLocator-Container {
  z-index: 100;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 728px;
  padding: 50px 25px 50px 46px;
  //font-family: 'hurmegeometric';
  background-color: #fff;
  transform: translateX(100%);
  transition: 0.6s;
  overflow-y: scroll;

  &--open {
    transform: translateX(0);
  }
}

.CromologyStoreLocator-Header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 27px;
  color: #111111;
  border-bottom: 1px solid #111111;
  font-size: 26px;
  font-weight: 800;
  font-family: 'hurmegeometric', sans-serif;
}

@media only screen and (max-width: 1024px) {
  .CromologyStoreLocator-Header {
    padding-bottom: 20px;
  }
}

.CromologyStoreLocator-HeaderSubtitle {
  margin-top: 10px;
  color: indianred;
}

.CromologyStoreLocator-HeaderButton {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .CromologyStoreLocator-HeaderButton {
    width: 15px;
    height: 15px;
  }
}

.CromologyStoreLocator-Search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: 27px;
  margin-bottom: 20px;

  span {
    font-size: 14px;
  }
}

.CromologyStoreLocator-SearchContainer {
  position: relative;
  display: flex;
  flex-grow: 2;
  flex-flow: column;
  width: 100%;
}

.CromologyStoreLocator-SearchSeparator {
  margin: 0 24px;
}

.CromologyStoreLocator-SearchInput {
  width: 100%;
  padding: 12px 12px 13px 12px;
  padding-left: 55px;
  font-size: 0.7rem;
  line-height: 1rem;
  color: #000;
  border: none;
  background-color: #f6f6f4;
}

.CromologyStoreLocator-SearchContainerBordered {
  .CromologyStoreLocator-SearchInput {
    background-color: #fff;
    border-top: 1px solid #13595f;
    border-left: 1px solid #13595f;
    border-right: 1px solid #13595f;
    color: #13595f;
  }
}

.CromologyStoreLocator-SearchIcon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.CromologyStoreLocator-SearchDropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #13595f;
  border-left: 1px solid #13595f;
  border-right: 1px solid #13595f;

  li {
    padding: 6px 6px 6px 54px;
    color: #13595f;
    font-size: 16px;
    line-height: 22px;
    transition: all ease 0.3s;

    &:hover {
      cursor: pointer;
      color: #ffffff;
      background-color: #13595f;
    }
  }
}

.CromologyStoreLocator-Store {
  margin: 16px 0;
  padding: 20px 14px;
  background-color: #f6f6f4;
  cursor: pointer;
}

.CromologyStoreLocator-StoreBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.CromologyStoreLocator-StoreInfos {
  flex-grow: 3;

  span {
    display: block;
    font-size: 16px;
    line-height: 22px;
  }
}

.CromologyStoreLocator-StoreName {
  font-weight: 600;
}

.CromologyStoreLocator-StoreActions {
  display: flex;
  flex-direction: column;
}

.CromologyStoreLocator-StoreBoxTextButton {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.CromologyStoreLocator-StoreBoxDetails {
  height: auto;
  max-height: 0;
  padding: 0 14px;
  padding-left: 70px;
  font-size: 14px;
  background-color: #f6f6f4;
  transition: 1s;
  overflow: hidden;

  &--open {
    max-height: 300px;
    transition: 2s;
  }
}

.CromologyStoreLocator-StoreBoxDetailsInfos {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.CromologyStoreLocator-StoreSchedule {
  flex-grow: 1;

  p {
    margin: 5px 0;
  }

  strong {
    display: inline-block;
    width: 65px;
  }
}

.CromologyStoreLocator-StoreContact {
  flex-grow: 1;
}

.CromologyStoreLocator-SpecialHours {
  margin-bottom: 20px;

  strong {
    margin-right: 10px;
  }
}

.CromologyStoreLocator-SpecialHoursDate {
  margin-right: 20px;
}

// Radio button override
input[type='radio'] {
  margin: 0 20px;
  -webkit-appearance: none;
  width: 28px;
  height: 28px;
  background-color: #ffffff;
  border: 1px solid #b4b0a9;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  margin: 8px auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: #13595f;
}

// Mobile
@media only screen and (max-width: 1024px) {
  .CromologyStoreLocator-Container {
    left: 0;
    // right: 0;
    width: auto;
    padding: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .CromologyStoreLocator-Search {
    flex-direction: column;
  }

  .CromologyStoreLocator-SearchSeparator {
    margin: 10px 0;
  }

  .CromologyStoreLocator-StoreBox {
    flex-direction: column;
    justify-content: space-between;

    input[type='radio'] {
      margin-left: 0;
    }
  }

  .CromologyStoreLocator-StoreInfos {
    margin: 0 0 20px 0;
  }

  .CromologyStoreLocator-StoreBoxDetails {
    padding-left: 46px;
  }

  .CromologyStoreLocator-StoreBoxDetailsInfos {
    display: block;
  }

  .CromologyStoreLocator-StoreContact {
    margin-top: 20px;
  }

  .CromologyStoreLocator-StoreActions {
    width: 100%;
  }

  .CromologyStoreLocator-Button {
    width: 100%;
  }
}

@import "@/assets/theme/default/scss/components/store-locator";
</style>
