










































































import { SfButton, SfDivider, SfImage, SfSidebar } from '@storefront-ui/vue';
import { defineComponent, useContext, useRouter } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import SvgImage from '@/components/General/SvgImage';
import productPlaceholder from '@storefront-ui/shared/images/product_placeholder.svg';
import { cachePrice } from '~/helpers/customer/role.ts';
import XsellProducts from '~/modules/catalog/product/components/XsellProducts.vue';
import { useProductStore } from '~/modules/catalog/product/stores/product';

export default defineComponent({
  name: 'AddToCartRecap',
  components: {
    SfSidebar,
    SfButton,
    SvgImage,
    SfImage,
    SfDivider,
    XsellProducts
  },
  setup() {
    const { isAddToCartSidebarOpen, toggleAddToCartSidebar } = useUiState();
    const router = useRouter();
    const { app } = useContext();
    const { canViewPrice } = cachePrice();
    const productStore = useProductStore();

    const goToCart = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
      toggleAddToCartSidebar();
    }

    return {
      isAddToCartSidebarOpen,
      toggleAddToCartSidebar,
      productPlaceholder,
      goToCart,
      canViewPrice,
      productStore
    }
  }
});
