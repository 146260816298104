





















































import { SfButton, SfDivider, SfImage, SfSidebar, SfLoader } from '@storefront-ui/vue';
import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import XsellProducts from '~/modules/catalog/product/components/XsellProducts.vue';
import useWishlist from '~/modules/wishlist/composables/useWishlist';

export default defineComponent({
  name: 'SelectWishlist',
  components: {
    SfSidebar,
    SfButton,
    SfImage,
    SfDivider,
    SfLoader,
    SvgImage,
    XsellProducts
  },
  setup(props, { emit }) {
    const { getWishlists, loading } = useWishlist();
    const { toggleAddToWishlistSidebar } = useUiState()
    const selectedWishlist = ref(0)
    const newWishlistName = ref('')
    const wishlists = ref([])

    const selectCreateWishlist = () => {
      selectedWishlist.value = -1
    }

    const resetNewWishlist = () => {
      newWishlistName.value = ''
    }

    const goToWishlist = () => {
      // @ts-ignore
      emit('addToWishlist', selectedWishlist.value, newWishlistName.value)
    }

    onMounted(async () => {
      wishlists.value = await getWishlists()
      selectedWishlist.value = wishlists.value?.[0]?.id || 0
    })

    return {
      toggleAddToWishlistSidebar,
      selectCreateWishlist,
      resetNewWishlist,
      goToWishlist,
      wishlists,
      selectedWishlist,
      newWishlistName,
      loading
    }
  }
});
