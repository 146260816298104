




































import { SfButton, SfSidebar, } from '@storefront-ui/vue';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useUiState, useWishlist } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'CreateWishlistSidebar',
  components: {
    SvgImage,
    SfSidebar,
    SfButton,
  },
  props: {},
  setup() {
    const { addWishlist } = useWishlist();
    const { isCreateWishlistSidebarOpen, toggleCreateWishlistSidebar } = useUiState();

    const newWishlistName = ref('');

    const onAddWishlist = async () => {
      await addWishlist(newWishlistName.value);
      newWishlistName.value = '';
      toggleCreateWishlistSidebar();
    }

    return {
      onAddWishlist,
      newWishlistName,
      isCreateWishlistSidebarOpen,
      toggleCreateWishlistSidebar,
    };
  },
});
