






























































































import { computed, defineComponent, onMounted, ref, useContext } from '@nuxtjs/composition-api';
import useXsellProducts from '~/modules/catalog/product/composables/useXsellProducts';
import calculStock from '~/helpers/utils/calculStock';
import calculFlap from '~/helpers/utils/calculFlap';
import { useUser } from '~/modules/customer/composables/useUser';
import { useImage, useProductConditions, useUiState } from '~/composables';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import PictimeProductCard from '~/components/organisms/PictimeProductCard';
import { ProductConditionQuery } from '~/custom-api/api/productConditions/types';
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'XsellProducts',
  components: {
    PictimeProductCard,
    SfButton
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    showWishlist: {
      type: Boolean,
      default: true
    },
    isWishlistPopin: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { getMagentoImage, imageSizes } = useImage();
    const { isAuthenticated } = useUser();
    const isAuth = ref(false);
    const { fetchProductConditions } = useProductConditions();
    const getTaxe = (isAuth) => {
      return !isAuth ? ' TTC' : ' HT'
    };
    const id = props.id;
    const { search, loading } = useXsellProducts();
    const nbProducts = 3;
    const products = ref([]);
    const priceLoading = ref(true);
    const pricesConditions = ref([] as ProductConditionQuery[]);
    const { app } = useContext();
    const { toggleAddToCartSidebar } = useUiState();

    const mappedProducts = computed(() => products?.value?.map((product) => ({
      ...product
    })));

    const productConditions = async () => {
      priceLoading.value = true;
      const token = app.$cookies.get('shop_token_id', { parseJSON: false });
      const tokenId = token ? token : '';
      const products = mappedProducts?.value?.map(product => ({ sku: product.sku, pricingUnit: '', quantity: 1 }));

      pricesConditions.value = await fetchProductConditions(
        {
          products,
          tokenId: tokenId
        }
      );
      priceLoading.value = false;
    };

    onMounted(async () => {
      isAuth.value = await isAuthenticated();

      const baseSearchQuery = {
        filter: {
          sku: {
            eq: id,
          },
        },
      };
      products.value = await search(baseSearchQuery);

      // On charge les conditions après que les produits soient ok
      if (products.value) {
        await productConditions();
      }
    });

    return {
      products,
      loading,
      isAuth,
      productGetters,
      calculStock,
      calculFlap,
      getTaxe,
      getMagentoImage,
      imageSizes,
      nbProducts,
      pricesConditions,
      priceLoading,
      toggleAddToCartSidebar,
    };
  },
});
