import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseSubAccountErrors, UseSubAccountInterface } from './useSubAccount';

export function useSubAccount(): UseSubAccountInterface {
  const { app } = useContext();
  const loading = ref(false);

  const error = ref<UseSubAccountErrors>({
    getList: null,
    get: null,
    create: null,
    update: null,
    remove: null,
  });

  const getList = async (clientCode) => {
    Logger.debug(`useSubAccount/getList`, clientCode);

    let result = null;
    const apiState = app.context.$vsf.$magento.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.getListSubAccount(clientCode);
      Logger.debug(`[Result] useSubAccount/getList : `, result);
      error.value.getList = null;
    } catch (err) {
      error.value.getList = err;
      Logger.error(`useSubAccount/getList`, err);
    } finally {
      loading.value = false;
    }

    return result;
  }

  const get = async (clientCode, subAccountId) => {
    Logger.debug(`useSubAccount/get`, [clientCode, subAccountId]);

    let result = null;
    const apiState = app.context.$vsf.$magento.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.getListSubAccount(clientCode);
      result = result.data.getListSubAccount.find((subAccount) => subAccount.role.customer_id === subAccountId);
      Logger.debug(`[Result] useSubAccount/get : `, result);
      error.value.get = null;
    } catch (err) {
      error.value.get = err;
      Logger.error(`useSubAccount/get`, err);
    } finally {
      loading.value = false;
    }

    return result;
  }

  const create = async (input) => {
    Logger.debug(`useSubAccount/create`, input);

    let result = null;
    const apiState = app.context.$vsf.$magento.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.createSubAccountCustomer(input);
      Logger.debug(`[Result] useSubAccount/create : `, result);
      error.value.create = null;
    } catch (err) {
      error.value.create = err;
      Logger.error(`useSubAccount/create`, err);
    } finally {
      loading.value = false;
    }

    return result;
  }

  const update = async (input) => {
    Logger.debug(`useSubAccount/update`, input);

    let result = null;
    const apiState = app.context.$vsf.$magento.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.updateSubAccountCustomer(input);
      Logger.debug(`[Result] useSubAccount/update : `, result);
      error.value.create = null;
    } catch (err) {
      error.value.create = err;
      Logger.error(`useSubAccount/update`, err);
    } finally {
      loading.value = false;
    }

    return result;
  }

  const remove = async (entityId) => {
    Logger.debug(`useSubAccount/remove`, entityId);

    let result = null;
    const apiState = app.context.$vsf.$magento.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.removeSubAccountCustomer(entityId);
      Logger.debug(`[Result] useSubAccount/remove : `, result);
      error.value.create = null;
    } catch (err) {
      error.value.create = err;
      Logger.error(`useSubAccount/remove`, err);
    } finally {
      loading.value = false;
    }

    return result;
  }

  return {
    getList,
    get,
    create,
    update,
    remove,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useSubAccount';
export default useSubAccount;
