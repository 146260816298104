




































import LazyHydrate from 'vue-lazy-hydration';
import { defineComponent, onMounted, ref, useRoute, watch } from '@nuxtjs/composition-api';
import { useUiState, useUser } from '~/composables';
import PictimeHeader from '~/components/organisms/PictimeHeader';
import BottomNavigation from '~/components/BottomNavigation';
import IconSpriteTollens from '~/components/General/IconSpriteTollens';
import IconSpriteZolpan from '~/components/General/IconSpriteZolpan';
import TopBar from '~/components/TopBar/TopBar';
import CromologyStoreLocator from '~/components/CromologyStoreLocator';
import { useCurrentShop } from '~/modules/customer/composables/useCurrentShop';
import BannerConnectAs from '~/components/molecules/BannerConnectAs.vue';
import { customerShopCode } from '~/modules/customer/helpers/customerShopCode';
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    BannerConnectAs,
    LazyHydrate,
    PictimeHeader,
    BottomNavigation,
    IconSpriteTollens,
    IconSpriteZolpan,
    TopBar,
    CromologyStoreLocator,
    CromoFooter: () => import(/* webpackPrefetch: true */ '~/components/CromoFooter.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    PasswordSidebar: () => import(/* webpackPrefetch: true */ '~/components/PasswordSidebar.vue'),
  },

  data() {
    return {
      openLocator: false,
    }
  },

  setup() {
    const { isAuthenticated } = useUser();
    const route = useRoute();
    const {
      isLoginModalOpen, toggleLoginModal, isPasswordSidebarOpen
    } = useUiState();

    const { setCustomerShopCode } = customerShopCode();
    const { set: setCurrent, get: getCurrent } = useCurrentShop();

    const setCurrentShopCode = async (code) => {
      let result = await setCurrent(code);
      if (result.current_shop) {
        setCustomerShopCode(result.current_shop);
        window.location.reload();
      }
    };

    const shopCode = ref('');
    const customerStore = useCustomerStore();
    const { currentShop } = storeToRefs(customerStore);

    onMounted(async () => {
      await isAuthenticated();
      const current = await getCurrent();
      setCustomerShopCode(current.current_shop);
    })

    watch(currentShop, async () => {
      shopCode.value = customerStore.getCurrentShopCode;
    });

    return {
      isLoginModalOpen,
      toggleLoginModal,
      route,
      isPasswordSidebarOpen,
      shopCode,
      setCurrentShopCode
    };
  },
});
