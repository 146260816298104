<template>
  <div v-if="canViewPrice" class="pic-price__container">
    <span v-if="isFromPrice" class="pic-price__text">à partir de</span>
    <SfPrice
      :class="{ 'display-none': !regularPrice }"
      class="pic-price__price"
    >
      <template #regular>
        <div>
          <div :class="[{'display-none': !regularPrice || specialPrice }, 'sf-price__regular', 'sf-price__regular--' + reducClass]">
            {{ (isFromPrice ? regularPrice : packagingRegularPrice) + taxe + (isFromPrice ? "/" + unitMeasure : "") }}
          </div>
          <div v-if="!isFromPrice" class="pic-price__text">Soit {{ regularPrice + taxe + "/" + unitMeasure }}</div>
        </div>
      </template>
    </SfPrice>
  </div>
</template>

<script>
import { SfPrice } from "@storefront-ui/vue";
import { cachePrice } from "~/helpers/customer/role";

export default {
  name: "PictimePrice",
  components: {
    SfPrice
  },

  props: {
    packagingRegularPrice: {
      type: [Number, String],
      default: null,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    flap: {
      type: String,
      default: ""
    },
    taxe: {
      type: String,
      default: " TTC"
    },
    isFromPrice: {
      type: Boolean,
      default: true
    },
    unitMeasure: {
      type: String,
      default: ""
    }
  },

  computed: {
    reducClass() {
      if (this.flap === "promo") {
        return "promo";
      } else if (this.flap === "remise") {
        return "price";
      } else if (this.flap === "nouveau") {
        return "new";
      } else if (this.flap === "destock") {
        return "destock";
      } else {
        return ""
      }
    }
  },

  setup() {
    const { canViewPrice } = cachePrice();

    return {
      canViewPrice
    }
  }
}
</script>

<style lang="scss" scoped>
.sf-price {
  &__regular {
    @include font(
        --product-description-font,
        var(--font-weight--bold),
        var(--font-size--base),
        1.4,
        var(--font-family--primary)
    );

    &--price {
      color: var(--c-prix-negoc);
    }

    &--promo {
      color: var(--c-promo);
    }
  }
}

.pic-price {
  &__container {
    display: flex;
    align-items: baseline;
  }

  &__text {
    margin-right: 4px;
    color: var(--c-dark);
    @include font(
        --product-description-font,
        var(--font-weight--normal),
        var(--font-size--xs),
        1.4,
        var(--font-family--primary)
    );
  }
}
</style>
