<template>
  <div class="mega-menu-mobile-navbar">
    <HeaderNavigationItemsPromo
      v-if="isAuth"
      :promo="promo"
      class="smartphone-only"
    />
    <HeaderNavigationItemMobile
      v-for="(category, index) in categoryTree"
      v-if="category && ((category.product_count > 0 && (category.category_type === 0)) || category.category_type === 1 )"
      :key="index"
      :cattype="category.category_type"
      :index="index"
      :items="category.children"
      :label="category.name"
      :link="localePath(getAgnosticCatLink(category))"
    />
    <HeaderNavigationItemMobile
      v-for="(category, index) in categoryTreeAdditional"
      :key="'additional_'+index"
      :cattype="category.category_type"
      :index="index"
      :items="category.children"
      :label="category.name"
      :link="localePath(getAgnosticCatLink(category))"
    />
    <HeaderNavigationItemsLink
      v-if="link"
      :categoryType="link.category_type"
      :link="link"
      :name="link.name"
      :urlPath="link.custom_url ? link.custom_url : link.url_path"
      class="smartphone-only"
    />
  </div>
</template>
<script>
import { onMounted, ref } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUiHelpers } from '~/composables';
import HeaderNavigationItemMobile from '~/components/Header/Navigation/HeaderNavigationItemMobile.vue';
import HeaderNavigationItemsLink from '~/components/Header/Navigation/HeaderNavigationItemsLink.vue';
import HeaderNavigationItemsPromo from '~/components/Header/Navigation/HeaderNavigationItemsPromo.vue';

export default {
  name: 'HeaderNavigationItemsMobile',
  components: {
    HeaderNavigationItemMobile,
    HeaderNavigationItemsLink,
    HeaderNavigationItemsPromo
  },
  props: {
    categoryTree: {
      type: Array,
      default() {
        return []
      }
    },
    categoryTreeAdditional: {
      type: Array,
      default() {
        return []
      }
    },
    link: {
      type: [String, Object],
      default: '',
    },
    promo: {
      type: [String, Object],
      default: '',
    }
  },
  setup() {
    const { getCatLink: getAgnosticCatLink } = useUiHelpers();
    const { isAuthenticated }                = useUser();
    const isAuth                             = ref(false);

    onMounted(async () => {
      isAuth.value = await isAuthenticated();
    })

    return {
      getAgnosticCatLink,
      isAuth
    }
  }
};
</script>
