





















import { SfLink } from '@storefront-ui/vue';

import { defineComponent } from '@nuxtjs/composition-api';

import HeaderLogo from '~/components/HeaderLogo.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import BannerConnectAs from '~/components/molecules/BannerConnectAs.vue';

export default defineComponent({
  name: 'PictimeHeaderTunnel',
  components: {
    BannerConnectAs,
    HeaderLogo,
    SvgImage,
    SfLink,
  }
});
