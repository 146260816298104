import { readonly, ref, useContext, } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseContactErrors, UseContactInterface } from './useContact';

export function useContact(): UseContactInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseContactErrors>({ sendContact: null });

  const sendContact = async (input) => {
    Logger.debug(`useContact/sendContact`, input);

    let result = null;

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.sendContactEmail(input);
      Logger.debug(`[Result] useContact/sendContact : `, result);
      error.value.sendContact = null;
    } catch (err) {
      error.value.sendContact = err;
      Logger.error(`useContact/sendContact`, err);
    } finally {
      loading.value = false;
    }

    return result;
  };

  return {
    sendContact,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export * from './useContact';
export default useContact;
