<template>
  <HTMLContent
    :content="shopName"
    class="shop-name"
  />
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import HTMLContent from '~/components/HTMLContent';

export default defineComponent({
  name: "ShopName",
  components: { HTMLContent },
  props: {
    brand: {
      type: String,
      required: true,
    },
    locality: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const shopName = computed(() => {
      return props.brand + ' ' + props.locality;
    });

    return {
      shopName,
    };
  },
});
</script>

<style scoped>
.shop-name {
  font-weight: var(--font-weight--bold);
}
</style>
