import type { GetActiveMessagesInterface } from './useGetActiveMessages';
import type { Ref } from '@nuxtjs/composition-api';
import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';

/**
 * @public
 *
 * Return messages data
 */
export function useGetActiveMessages(): GetActiveMessagesInterface {

  const loading: Ref<boolean> = ref(false);
  const { app } = useContext();
  const context = app.$vsf;

  const loadMessage = async (customer_id) => {
    Logger.debug('[Magento] before load messages');
    let results = [];

    try {
      loading.value = true;
      const { data } = await context.$magento.api.getActiveMessages(customer_id);
      if (data && data.getActiveMessages) {
        results = data?.getActiveMessages[0] ?? [];
      }

      Logger.debug('[Magento] loaded messages:', results);
    } catch (err) {
      Logger.error('Magento] load messages', err);
    }
    return results;
  }

  return { loadMessage };
}

export * from './useGetActiveMessages';
export default useGetActiveMessages;
