/** GraphQL Query that fetches store configuration from the API */
export const StoreConfigQuery = `
  query storeConfig {
    storeConfig {
      allow_guests_to_write_product_reviews,
      allow_items,
      allow_order,
      banner_promo_image_url_desktop,
      banner_promo_image_url_mobile,
      maximum_distance_to_order,
      base_currency_code,
      base_media_url,
      catalog_default_sort_by,
      category_fixed_product_tax_display_setting,
      cms_home_page,
      cms_no_cookies,
      cms_no_route,
      configurable_thumbnail_source,
      copyright,
      default_description,
      default_display_currency_code,
      default_keywords,
      default_title,
      grid_per_page,
      grid_per_page_values,
      head_shortcut_icon,
      header_logo_src,
      is_default_store,
      is_default_store_group,
      label_destockage,
      link_access_part,
      link_discover,
      list_mode,
      list_per_page,
      list_per_page_values,
      locale,
      register_success_message,
      login_error_message,
      logo_alt,
      logo_height,
      logo_width,
      magento_wishlist_general_is_enabled,
      minimum_password_length,
      no_route,
      product_fixed_product_tax_display_setting,
      product_reviews_enabled,
      required_character_classes_number,
      root_category_uid,
      sales_fixed_product_tax_display_setting,
      store_code,
      store_group_code,
      store_group_name,
      store_name,
      store_sort_order,
      tester_product_id,
      timezone,
      title_prefix,
      title_separator,
      title_suffix,
      use_store_in_url,
      url_expert,
      website_code,
      website_name,
      weight_unit,
      welcome,
      subaccount_messages {
        key,
        value
      },
      contact_general_reason,
      cgv_message
    }
  }
`;

export default StoreConfigQuery;
