<template>
  <div v-if="actionsWrapper" class="stock">
    <div class="stock__container">
      <SvgImage
        v-if="clickAndCollect"
        class="navbar__filters-icon icon"
        height="6"
        icon="Icon_check_vert"
        width="10"
      />
      <SvgImage
        v-else
        class="navbar__filters-icon icon "
        height="8"
        icon="Icon_fermer"
        width="10"
      />
      <span class="stock__label">{{ storeLabel }}</span>
    </div>
    <div class="stock__container">
      <SvgImage
        v-if="livraison"
        class="navbar__filters-icon icon"
        height="6"
        icon="Icon_check_vert"
        width="10"
      />
      <SvgImage
        v-else
        class="navbar__filters-icon icon "
        height="8"
        icon="Icon_fermer"
        width="10"
      />
      <span class="stock__label">{{ deliveryLabel }}</span>
    </div>
  </div>
</template>

<script>
import SvgImage from "~/components/General/SvgImage";

export default {
  name: "PictimeStock",
  components: {
    SvgImage
  },

  props: {
    actionsWrapper: {
      type: Boolean,
      default: true,
    },
    clickAndCollect: {
      type: Boolean,
      default: false
    },
    storeLabel: {
      type: String,
      default: ""
    },
    livraison: {
      type: Boolean,
      default: false
    },
    deliveryLabel: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/theme/default/scss/components/molecules/pictime-stock.scss";
</style>
