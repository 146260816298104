import type { GetOptions, NuxtCookies } from 'cookie-universal-nuxt';
import type { CookieSerializeOptions } from 'cookie';
import { integrationPlugin } from '~/helpers/integrationPlugin';
import { mapConfigToSetupObject } from '~/modules/core/helpers';
import { defaultConfig } from '~/modules/cromology/defaultConfig';

const moduleOptions = JSON.parse('{}');

export default integrationPlugin((plugin) => {
  const getCookieName = (property: keyof typeof defaultConfig['cookies']): string => moduleOptions.cookies?.[property] ?? defaultConfig.cookies[property];
  const cookieOpts = {
    [defaultConfig.cookies.roleCookieName]: { ...moduleOptions.cookiesDefaultOpts },
  };

  const getCookieOpt = (cookieName?: string): CookieSerializeOptions => cookieOpts[cookieName] ?? {};

  const cookieNames = {
    role: getCookieName('roleCookieName'),
  };

  const { $cookies } = plugin.app;

  const createCookieOperationsInstance = <TValue = string>(cookies: NuxtCookies) => (cookieName: string) => ({
    get: (opts?: GetOptions) => cookies.get(cookieName, opts),
    set: (value: TValue, opts?: CookieSerializeOptions) => cookies.set(cookieName, value, { ...getCookieOpt(cookieName), ...opts }),
    remove: (opts?: CookieSerializeOptions) => cookies.remove(cookieName, opts),
  });

  const createCookieOperations = createCookieOperationsInstance($cookies);

  const {
    get: getCustomerRole,
    set: setCustomerRole,
    remove: removeCustomerRole,
  } = createCookieOperations(cookieNames.role);

  const settings = mapConfigToSetupObject({
    moduleOptions,
    app: plugin.app,
    additionalProperties: {
      state: {
        getCustomerRole,
        setCustomerRole,
        removeCustomerRole
      },
    },
  });

  plugin.integration.configure('cromology', settings);
});
