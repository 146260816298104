import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseCreateCustomerErrors, UseCreateCustomerInterface } from './useCreateCustomer';

export function useCreateCustomer(): UseCreateCustomerInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseCreateCustomerErrors>({
    postCreateCustomer: null,
    verifyExistingClient: null
  });

  const search = async (input) => {
    Logger.debug(`useCreateCustomer/postCreateCustomer`, input);
    let createCustomer = null;

    try {
      loading.value = true;

      // @ts-ignore @TODO add establishment to ApiClientMethods
      createCustomer = await app.$vsf.$magento.api.createCustomer(input);

      Logger.debug(`[Result] useCreateCustomer/postCreateCustomer : `, createCustomer);

      error.value.postCreateCustomer = null;
    } catch (err) {
      error.value.postCreateCustomer = err;
      Logger.error(`useCreateCustomer/postCreateCustomer`, err);
    } finally {
      loading.value = false;
    }

    return createCustomer;
  };

  const verifyExistingAccount = async (input) => {
    Logger.debug(`useCreateCustomer/verifyExistingAccount`, input);
    let verifyClient = null;

    try {
      loading.value = true;

      // @ts-ignore @TODO add establishment to ApiClientMethods
      verifyClient = await app.$vsf.$magento.api.verifyExistingAccount(input);

      Logger.debug(`[Result] useCreateCustomer/verifyExistingAccount : `, verifyClient);

      error.value.verifyExistingClient = null;
    } catch (err) {
      error.value.verifyExistingClient = err;
      Logger.error(`useCreateCustomer/verifyExistingAccount`, err);
    } finally {
      loading.value = false;
    }

    return verifyClient;
  };

  return {
    search,
    verifyExistingAccount,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useCreateCustomer';
export default useCreateCustomer;
