import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { UseConvertQuoteToOrderErrors, UseConvertQuoteToOrderInterface } from './useConvertQuoteToOrder';

export function useConvertQuoteToOrder(): UseConvertQuoteToOrderInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseConvertQuoteToOrderErrors>({ convertQuoteToOrder: null });

  const convertQuoteToOrder = async (input) => {
    Logger.debug(`useConvertQuoteToOrder/convertQuoteToOrder`, input);

    let result = null;

    try {
      loading.value = true;
      // @ts-ignore @TODO add establishment to ApiClientMethods
      result = await app.$vsf.$magento.api.convertQuoteToOrder(input);
      Logger.debug(`[Result] useConvertQuoteToOrder/convertQuoteToOrder : `, result);
      error.value.convertQuoteToOrder = null;
    } catch (err) {
      error.value.convertQuoteToOrder = err;
      Logger.error(`useConvertQuoteToOrder/convertQuoteToOrder`, err);
    } finally {
      loading.value = false;
    }

    return result;
  };

  return {
    convertQuoteToOrder,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export * from './useConvertQuoteToOrder';
export default useConvertQuoteToOrder;
